import React, { ChangeEvent } from 'react';
import { FormControl, Grid, InputAdornment, TextField } from '@mui/material';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';

interface TextInputProps {
    id: string;
    label: string;
    iconLabel?: JSX.Element;
    handleChange: (e: ChangeEvent<any>) => void;
    handleBlur: (e: ChangeEvent<any>) => void;
    hasSubmitted: boolean;
    touched?: boolean;
    errors?: string;
    value?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string | JSX.Element;
    submitForm?: () => Promise<void>;
    multiline?: boolean;
    startAdornment?: string;
}

const TextInput: React.FC<TextInputProps> = ({
    id,
    label,
    iconLabel,
    handleChange,
    handleBlur,
    hasSubmitted,
    touched,
    errors,
    value,
    type = 'text', // default type to "text"
    enableLabel,
    isMobile,
    caption,
    submitForm,
    multiline,
    startAdornment,
}) => {
    // Function to handle "Enter" key press
    const handleKeyDown = async (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && submitForm) {
            e.preventDefault(); // Prevent default form submission
            await submitForm(); // Call submitForm if provided
        }
    };

    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'center'}
                spacing={2}
            >
                {/* Label Area */}
                {!enableLabel && (
                    <LabelInput
                        iconLabel={iconLabel}
                        label={label}
                        enableLabel={enableLabel}
                        isMobile={isMobile}
                        caption={caption}
                    />
                )}

                {/* Text Field Area */}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                    lg={enableLabel ? 12 : 9.8}
                    xl={enableLabel ? 12 : 10.25}
                >
                    <TextField
                        multiline={multiline}
                        fullWidth
                        id={id}
                        name={id}
                        label={
                            enableLabel
                                ? label // Show label if enabled and value > 0
                                : undefined
                        }
                        value={value}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched && Boolean(errors)}
                        helperText={touched && errors}
                        margin="none"
                        disabled={hasSubmitted}
                        type={type}
                        onKeyDown={handleKeyDown}
                        slotProps={{
                            input: {
                                ...(startAdornment
                                    ? {
                                          startAdornment: (
                                              <InputAdornment position="start">
                                                  {startAdornment}
                                              </InputAdornment>
                                          ),
                                      }
                                    : {}),
                            },
                        }}
                    />
                    {enableLabel && caption && (
                        <CaptionInput caption={caption} />
                    )}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default TextInput;
