import React, { useEffect, useState } from 'react';
import {
    AvatarGroup,
    Button,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
} from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import {
    isUploadHearted,
    isUploadSubscribed,
    PostUploadApprovalProps,
    Report,
    Upload,
    UploadApprovalStatusRequest,
    UploadStatus,
    User,
    UserAccountTypes,
} from '../../workers/ApiWorker';
import { convertSimple } from '../../helpers/Date';
import Box from '@mui/material/Box';
import UserAvatar from '../user/UserAvatar';
import { useApi } from '../../contexts/ApiContext';
import RejectUploadModal from '../modal/RejectUploadModal';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ReportUploadModal from '../modal/ReportUploadModal';
import ViewReportsModal from '../modal/ViewReportsModal';
import { UPDATE_UPLOAD_UPDATE_STATUS_FAILED } from '../../helpers/Messages';
import DeleteUploadModal from '../modal/DeleteUploadModal';
import {
    Delete,
    Download,
    Favorite,
    NewReleases,
    CheckCircle,
    Cancel,
    Star,
    DocumentScanner,
    Update,
    HourglassTop,
    WarningAmber,
    Pending,
} from '@mui/icons-material';
import Card from '@mui/material/Card';
import {
    BackgroundDefaultColor,
    OrangeColor,
    TextCardPreviewDescriptionColor,
} from '../../helpers/Themes';
import Tags from './Tags';
import { formatNumberToReadable } from '../../helpers/Numbers';
import {
    calculateTimeLeft,
    canUserAlwaysAccessUpload,
    getUploadStatus,
    hasOpenReports,
    isUploadPublic,
    isVersionPublic,
    removalDay,
    renderTimeLeft,
    TimeLeftType,
} from '../../helpers/Uploads';
import { isUserVerified } from '../../helpers/Users';
import UpdateUploadModal from '../modal/UpdateUploadModal';
import { SiPatreon } from 'react-icons/si';
import PatreonIcon from '../universal/icons/PatreonIcon';

interface ActionsAreaProps {
    upload: Upload;
    isMobile: boolean;
    user?: User;
    setUpload: (upload: Upload) => void;
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const ActionsArea: React.FC<ActionsAreaProps> = ({
    upload,
    isMobile,
    user,
    setUpload,
}) => {
    const apiWorker = useWorker(createApiWorker);

    const { token } = useApi();
    const { theme } = useCustomTheme();

    const [success, setSuccess] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | undefined>(undefined);

    const [activeReportId, setActiveReportId] = useState<string | null>(null);

    const [timeLeftForRelease, setTimeLeftForRelease] =
        useState<TimeLeftType>(''); // Store hours, minutes, seconds
    const [timeLeftForReleasePublic, setTimeLeftForReleasePublic] =
        useState<TimeLeftType>(''); // Store hours, minutes, seconds
    const [timeLeftForReleasePrivate, setTimeLeftForReleasePrivate] =
        useState<TimeLeftType>(''); // Store hours, minutes, seconds

    const [isReportUploadModalOpen, setIsReportUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility
    const [isViewReportsUploadModalOpen, setIsViewReportsUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility
    const [reports, setReports] = useState<Report[]>([]);

    const [totalDownloads, setTotalDownloads] = useState<number>(
        upload.stats.downloads
    );

    const [isHearted, setIsHearted] = useState<boolean>(
        isUploadHearted(upload) ? upload.userHearted : false
    );

    const [isSubscribed, setIsSubscribed] = useState<boolean>(
        isUploadSubscribed(upload) ? upload.userSubscribed : false
    );

    const [hearts, setHearts] = useState<number>(upload.stats.hearts);

    const [isUpdateUploadModalOpen, setIsUpdateUploadModalOpen] =
        useState<boolean>(false);

    const [isDeleteUploadModalOpen, setIsDeleteUploadModalOpen] =
        useState<boolean>(false);

    const [isDeclineUploadModalOpen, setIsDeclineUploadModalOpen] =
        useState<boolean>(false); // State for modal visibility

    const [selectedVersionIndex, setSelectedVersionIndex] = useState<number>(
        upload.versions.length - 1
    );

    const handleSubscribeClick = async () => {
        if (token && user) {
            try {
                await apiWorker.postUploadSubscribe(upload.uuid, token);

                setIsSubscribed((prev) => !prev);
            } catch (error) {
                console.warn('Could not update subscribe', error);
            }
        }
    };

    const handleHeartClick = async () => {
        if (token && user) {
            try {
                await apiWorker.postUploadLike(upload.uuid, token);

                setHearts((prevHearts) =>
                    isHearted ? prevHearts - 1 : prevHearts + 1
                );
                setIsHearted(!isHearted);
            } catch (error) {
                console.warn('Could not update like', error);
            }
        }
    };

    const handleDownloadClick = async () => {
        try {
            const download = await apiWorker.getUploadVersionLink(
                {
                    uuid: upload.uuid,
                    versionUuid: upload.versions[selectedVersionIndex].uuid,
                },
                token
            );

            window.open(download.data.downloadLink, '_blank');

            if (token && user) {
                if (upload.author.uuid === user.uuid) {
                    return;
                }
            }

            setTotalDownloads(totalDownloads + 1);
        } catch (error) {
            console.warn('Could not get download link', error);
        }
    };

    const showViewReportsModal = () => {
        if (token) {
            const getReports = async () => {
                try {
                    const response = await apiWorker.getReportsByUpload(
                        upload.uuid,
                        token
                    );

                    if (response.data) {
                        setReports(response.data);
                    } else {
                        setReports([]);
                    }
                } catch (error) {
                    setReports([]);
                }
            };

            getReports()
                .then(() => {})
                .catch((error) => {
                    console.log('Error fetching reports for upload:', error);
                });

            setActiveReportId(null);
            setSuccess(undefined);
            setError(undefined);
            setIsViewReportsUploadModalOpen(true);
        }
    };

    const showReportModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(true);
    };

    const closeReportUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsReportUploadModalOpen(false);
    };

    const closeViewReportsModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setActiveReportId(null);
        setSuccess(undefined);
        setError(undefined);
        setIsViewReportsUploadModalOpen(false);
    };

    const showUpdateUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsUpdateUploadModalOpen(true);
    };

    const showDeleteUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(true);
    };

    const closeUpdateUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsUpdateUploadModalOpen(false);
    };

    const closeDeleteUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeleteUploadModalOpen(false);
    };

    const showDeclineUploadModal = () => {
        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(true);
    };

    const closeDeclineUploadModal = (resetForm?: () => void) => {
        if (resetForm) {
            resetForm();
        }

        setSuccess(undefined);
        setError(undefined);
        setIsDeclineUploadModalOpen(false);
    };

    const handleUpdateStatus = async (
        data: PostUploadApprovalProps
    ): Promise<boolean> => {
        setError(undefined);
        setSuccess(undefined);
        let complete = false;
        if (upload && data && token) {
            try {
                const response = await apiWorker.postUploadApproval(
                    upload.uuid,
                    token,
                    data
                );
                setUpload(response.data);
                complete = true;
            } catch (error) {
                setError(UPDATE_UPLOAD_UPDATE_STATUS_FAILED);
            }
        }
        return complete;
    };

    const [anchorElVersion, setAnchorElVersion] =
        React.useState<HTMLElement | null>(null);

    const versionOpen = Boolean(anchorElVersion);
    const handleVersionClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElVersion(event.currentTarget as HTMLButtonElement);
    };
    const handleVersionClose = () => {
        setAnchorElVersion(null);
    };

    useEffect(() => {
        if (upload) {
            const latestVersion = upload.versions[selectedVersionIndex];

            const scheduledTimePrivate = latestVersion.scheduledAtPrivate
                ? Date.parse(latestVersion.scheduledAtPrivate)
                : null;
            const scheduledTimePublic = latestVersion.scheduledAtPublic
                ? Date.parse(latestVersion.scheduledAtPublic)
                : null;

            if (scheduledTimePrivate || scheduledTimePublic) {
                const interval = setInterval(() => {
                    if (scheduledTimePrivate) {
                        const remainingTimePrivate =
                            calculateTimeLeft(scheduledTimePrivate);
                        setTimeLeftForReleasePrivate(remainingTimePrivate);
                    }

                    if (scheduledTimePublic) {
                        const remainingTimePublic =
                            calculateTimeLeft(scheduledTimePublic);
                        setTimeLeftForReleasePublic(remainingTimePublic);
                    }

                    if (!scheduledTimePrivate && !scheduledTimePublic) {
                        clearInterval(interval); // Stop interval when both countdowns end
                    }
                }, 1000); // Update every second

                return () => clearInterval(interval); // Cleanup on component unmount
            }
        }
    }, [selectedVersionIndex]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {getUploadStatus(upload) === UploadStatus.PENDING && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: theme.palette.warning.main,
                    }}
                >
                    <Pending
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        PENDING | UNDER REVIEW
                    </Typography>
                </Card>
            )}

            {getUploadStatus(upload) === UploadStatus.REJECTED &&
                upload.declined && (
                    <Card
                        sx={{
                            marginBottom: 1,
                            padding: 1,
                            textAlign: 'left',
                            cursor: 'default',
                            display: 'flex', // Use flexbox for side-by-side layout
                            alignItems: 'center', // Align items vertically at the center
                            backgroundColor: theme.palette.error.main,
                        }}
                    >
                        <Cancel
                            sx={{
                                marginRight: 1.5,
                                color: BackgroundDefaultColor,
                            }}
                        />{' '}
                        {/* Add some space between the icon and text */}
                        <Typography
                            variant="body2"
                            color={BackgroundDefaultColor}
                        >
                            REJECTED |{' '}
                            {removalDay(
                                upload.declined.declinedAt
                            ).toUpperCase()}
                            <br />
                            <Typography component="span" variant="body2">
                                Reason: {upload.declined.reason}
                            </Typography>
                        </Typography>
                    </Card>
                )}

            {!isUploadPublic(upload, selectedVersionIndex) &&
            !isUserVerified(token, user) &&
            timeLeftForReleasePublic !== null ? (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: OrangeColor,
                    }}
                >
                    <WarningAmber
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        A VERIFIED ACCOUNT IS REQUIRED TO DOWNLOAD PATREON
                        UPLOADS
                    </Typography>
                </Card>
            ) : null}

            {getUploadStatus(upload, selectedVersionIndex) ===
                UploadStatus.COMINGSOON_PRIVATE && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: OrangeColor,
                    }}
                >
                    <HourglassTop
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        RELEASING |{' '}
                        {renderTimeLeft(
                            timeLeftForReleasePrivate
                        ).toUpperCase()}
                    </Typography>
                </Card>
            )}

            {getUploadStatus(upload, selectedVersionIndex) ===
                UploadStatus.COMINGSOON_EARLYACCESS && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: isUploadPublic(
                            upload,
                            selectedVersionIndex
                        )
                            ? theme.palette.warning.main
                            : OrangeColor,
                    }}
                >
                    <HourglassTop
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        EARLY ACCESS |{' '}
                        {renderTimeLeft(
                            timeLeftForReleasePrivate
                        ).toUpperCase()}
                    </Typography>
                </Card>
            )}

            {getUploadStatus(upload, selectedVersionIndex) ===
                UploadStatus.COMINGSOON_PUBLIC && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: theme.palette.warning.main,
                    }}
                >
                    <HourglassTop
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        RELEASING |{' '}
                        {renderTimeLeft(timeLeftForReleasePublic).toUpperCase()}
                    </Typography>
                </Card>
            )}

            {(getUploadStatus(upload, selectedVersionIndex) ===
                UploadStatus.EARLYACCESS ||
                getUploadStatus(upload, selectedVersionIndex) ===
                    UploadStatus.COMINGSOON_EARLYACCESS) && (
                <Card
                    sx={{
                        marginBottom: 1,
                        padding: 1,
                        textAlign: 'left',
                        cursor: 'default',
                        display: 'flex', // Use flexbox for side-by-side layout
                        alignItems: 'center', // Align items vertically at the center
                        backgroundColor: theme.palette.warning.main,
                    }}
                >
                    <HourglassTop
                        sx={{
                            marginRight: 1.5,
                            color: BackgroundDefaultColor,
                        }}
                    />{' '}
                    {/* Add some space between the icon and text */}
                    <Typography variant="body2" color={BackgroundDefaultColor}>
                        PUBLIC RELEASE |{' '}
                        {renderTimeLeft(timeLeftForReleasePublic).toUpperCase()}
                    </Typography>
                </Card>
            )}

            <Button
                disabled={
                    // ✅ Case 1: If version is public AND status is approved → enable button (false)
                    isVersionPublic(upload.versions[selectedVersionIndex]) &&
                    getUploadStatus(upload, selectedVersionIndex) ===
                        UploadStatus.APPROVED
                        ? false
                        : // ✅ Case 2: If user always has access → enable button (false)
                          canUserAlwaysAccessUpload(upload, user, token)
                          ? false
                          : // ✅ Case 3: Nested checks (handle remaining conditions)
                            (() => {
                                switch (
                                    getUploadStatus(
                                        upload,
                                        selectedVersionIndex
                                    )
                                ) {
                                    case UploadStatus.PENDING:
                                        return true;
                                    case UploadStatus.REJECTED:
                                        return true;
                                    case UploadStatus.APPROVED:
                                        if (
                                            !isVersionPublic(
                                                upload.versions[
                                                    selectedVersionIndex
                                                ]
                                            )
                                        ) {
                                            return !isUserVerified(token, user);
                                        } else {
                                            return false;
                                        }
                                    case UploadStatus.COMINGSOON_EARLYACCESS:
                                        if (!isUserVerified(token, user)) {
                                            return true;
                                        }

                                        return (
                                            timeLeftForReleasePrivate !== null
                                        );
                                    case UploadStatus.COMINGSOON_PRIVATE:
                                        if (!isUserVerified(token, user)) {
                                            return true;
                                        }

                                        return (
                                            timeLeftForReleasePrivate !== null
                                        );
                                    case UploadStatus.COMINGSOON_PUBLIC:
                                        return (
                                            timeLeftForReleasePublic !== null
                                        );
                                    case UploadStatus.EARLYACCESS:
                                        if (isUserVerified(token, user)) {
                                            return false;
                                        } else {
                                            return (
                                                timeLeftForReleasePublic !==
                                                null
                                            );
                                        }
                                    default:
                                        return true; // Default: Enable button
                                }
                            })()
                }
                fullWidth
                variant="contained"
                size="large"
                sx={{ marginBottom: 1, padding: 1, fontSize: '28px' }}
                onClick={handleDownloadClick}
            >
                <Download sx={{ fontSize: 28 }} /> DOWNLOAD
            </Button>

            {token &&
                user &&
                user.userProfile.accountType !== UserAccountTypes.MEMBER &&
                getUploadStatus(upload) === UploadStatus.PENDING &&
                user.userProfile.uuid !== upload.author.uuid &&
                handleUpdateStatus && (
                    <>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() =>
                                handleUpdateStatus({
                                    status: UploadApprovalStatusRequest.APPROVED,
                                })
                            }
                        >
                            <CheckCircle />
                            <Typography variant="body1">APPROVE</Typography>
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() => showDeclineUploadModal()}
                        >
                            <Cancel />
                            <Typography variant="body1">REJECT</Typography>
                        </Button>
                        {/* Modal Component */}
                        <RejectUploadModal
                            handleUpdateStatus={handleUpdateStatus}
                            closeModal={closeDeclineUploadModal}
                            setError={setError}
                            error={error}
                            isModalOpen={isDeclineUploadModalOpen}
                            isMobile={isMobile}
                        />
                    </>
                )}

            {token &&
                user &&
                user.userProfile.uuid !== upload.author.uuid &&
                (getUploadStatus(upload) === UploadStatus.APPROVED ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_PRIVATE ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_PUBLIC ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_EARLYACCESS ||
                    getUploadStatus(upload) === UploadStatus.EARLYACCESS) &&
                user.userProfile.accountType === UserAccountTypes.MEMBER && (
                    <>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() => showReportModal()}
                        >
                            <NewReleases />
                            <Typography variant="body1">
                                REPORT CONTENT
                            </Typography>
                        </Button>
                        <ReportUploadModal
                            closeModal={closeReportUploadModal}
                            setError={setError}
                            error={error}
                            isModalOpen={isReportUploadModalOpen}
                            isMobile={isMobile}
                            upload={upload}
                            success={success}
                            setSuccess={setSuccess}
                        />
                    </>
                )}

            {hasOpenReports(upload, user) ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{
                            textAlign: 'left',
                            padding: 1,
                            marginBottom: 1,
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            gap: 2,
                        }}
                        onClick={() => showViewReportsModal()}
                    >
                        <NewReleases />
                        <Typography variant="body1">VIEW REPORTS</Typography>
                    </Button>
                    <ViewReportsModal
                        closeModal={closeViewReportsModal}
                        setError={setError}
                        error={error}
                        isModalOpen={isViewReportsUploadModalOpen}
                        isMobile={isMobile}
                        uploadUuid={upload.uuid}
                        reports={reports}
                        success={success}
                        setSuccess={setSuccess}
                        setActiveReportId={setActiveReportId}
                        activeReportId={activeReportId}
                        setReports={setReports}
                        setUpload={setUpload}
                        activeUser={user}
                    />
                </>
            ) : null}

            {token &&
                user &&
                user.userProfile.uuid !== upload.author.uuid &&
                (getUploadStatus(upload) === UploadStatus.APPROVED ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_PRIVATE ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_PUBLIC ||
                    getUploadStatus(upload) ===
                        UploadStatus.COMINGSOON_EARLYACCESS ||
                    getUploadStatus(upload) === UploadStatus.EARLYACCESS) && (
                    <>
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            sx={{
                                textAlign: 'left',
                                padding: 1,
                                marginBottom: 1,
                                justifyContent: 'flex-start',
                                color: 'inherit',
                                textTransform: 'none',
                                gap: 2,
                            }}
                            onClick={() => handleSubscribeClick()}
                        >
                            <Star />
                            <Typography variant="body1">
                                {isSubscribed ? 'UNSUBSCRIBE' : 'SUBSCRIBE'}
                            </Typography>
                        </Button>
                    </>
                )}

            {token &&
            user &&
            (getUploadStatus(upload) === UploadStatus.APPROVED ||
                getUploadStatus(upload) === UploadStatus.PENDING ||
                getUploadStatus(upload) === UploadStatus.COMINGSOON_PRIVATE ||
                getUploadStatus(upload) === UploadStatus.COMINGSOON_PUBLIC ||
                getUploadStatus(upload) ===
                    UploadStatus.COMINGSOON_EARLYACCESS ||
                getUploadStatus(upload) === UploadStatus.EARLYACCESS) &&
            (user.userProfile.uuid === upload.author.uuid ||
                user.userProfile.accountType !== UserAccountTypes.MEMBER) ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{
                            textAlign: 'left',
                            padding: 1,
                            marginBottom: 1,
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            gap: 2,
                        }}
                        onClick={() => showDeleteUploadModal()}
                    >
                        <Delete />
                        <Typography variant="body1">DELETE</Typography>
                    </Button>
                    <DeleteUploadModal
                        closeModal={closeDeleteUploadModal}
                        setError={setError}
                        error={error}
                        isModalOpen={isDeleteUploadModalOpen}
                        isMobile={isMobile}
                        upload={upload}
                    />
                </>
            ) : null}

            {token &&
            user &&
            (getUploadStatus(upload) === UploadStatus.APPROVED ||
                getUploadStatus(upload) === UploadStatus.PENDING ||
                getUploadStatus(upload) === UploadStatus.COMINGSOON_PRIVATE ||
                getUploadStatus(upload) === UploadStatus.COMINGSOON_PUBLIC ||
                getUploadStatus(upload) ===
                    UploadStatus.COMINGSOON_EARLYACCESS ||
                getUploadStatus(upload) === UploadStatus.EARLYACCESS) &&
            user.userProfile.uuid === upload.author.uuid ? (
                <>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{
                            textAlign: 'left',
                            padding: 1,
                            marginBottom: 1,
                            justifyContent: 'flex-start',
                            color: 'inherit',
                            textTransform: 'none',
                            gap: 2,
                        }}
                        onClick={() => showUpdateUploadModal()}
                    >
                        <Update />
                        <Typography variant="body1">UPDATE</Typography>
                    </Button>
                    <UpdateUploadModal
                        closeModal={closeUpdateUploadModal}
                        setError={setError}
                        error={error}
                        isModalOpen={isUpdateUploadModalOpen}
                        isMobile={isMobile}
                        upload={upload}
                        setUpload={setUpload}
                        setSelectedVersionIndex={setSelectedVersionIndex}
                    />
                </>
            ) : null}

            {/* Stats - Views & Downloads */}
            <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <Card
                        sx={{
                            padding: 2,
                            textAlign: 'center',
                            cursor: 'default',
                        }}
                    >
                        <Typography variant="h4">
                            {formatNumberToReadable(upload.stats.views)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            VIEWS
                        </Typography>
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card
                        sx={{
                            padding: 2,
                            textAlign: 'center',
                            cursor: 'default',
                        }}
                    >
                        <Typography variant="h4">
                            {formatNumberToReadable(totalDownloads)}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                        >
                            DOWNLOADS
                        </Typography>
                    </Card>
                </Grid>
            </Grid>

            {/* Collaborators Section */}
            {upload.collaborators && upload.collaborators.length > 0 && (
                <Card sx={{ padding: 2, textAlign: 'center', marginBottom: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 1,
                            marginBottom: 1.5,
                            flexWrap: 'wrap', // Ensures wrapping when out of space
                        }}
                    >
                        <AvatarGroup
                            max={upload.collaborators.length}
                            sx={{ justifyContent: 'center', flexWrap: 'wrap' }} // Ensures the avatars wrap inside the group
                        >
                            {upload.collaborators.map((collaborator, index) => (
                                <UserAvatar
                                    key={index}
                                    backup={collaborator.username}
                                    dimensions={36}
                                    border={true}
                                    userProfile={collaborator}
                                    tooltip={true}
                                />
                            ))}
                        </AvatarGroup>
                    </Box>
                    <Typography
                        variant="body2"
                        color={TextCardPreviewDescriptionColor}
                        sx={{ cursor: 'default' }}
                    >
                        COLLABORATORS
                    </Typography>
                </Card>
            )}

            {/* Games & Likes Section */}
            <Grid container spacing={1} sx={{ marginBottom: 1 }}>
                <Grid item xs={6}>
                    <Card sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography
                            variant="h4"
                            sx={{
                                cursor: 'default',
                            }}
                        >
                            {upload.games[0].name.toUpperCase()}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{
                                cursor: 'default',
                            }}
                        >
                            {upload.games[0].system.toUpperCase()}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                            sx={{
                                cursor: 'default',
                            }}
                        >
                            GAMES
                        </Typography>
                        {upload.games.length > 1 && (
                            <Tooltip
                                disableInteractive
                                title={
                                    <Box sx={{ padding: 1 }}>
                                        {upload.games
                                            .slice(1)
                                            .map((game, index) => (
                                                <Typography
                                                    key={index}
                                                    variant="body2"
                                                >
                                                    {game.name} ({game.system})
                                                </Typography>
                                            ))}
                                    </Box>
                                }
                                placement="bottom"
                                arrow
                            >
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: TextCardPreviewDescriptionColor,
                                        cursor: 'pointer',
                                    }}
                                >
                                    + {upload.games.length - 1} more
                                </Typography>
                            </Tooltip>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={6}>
                    <Card
                        sx={{
                            padding: 2,
                            textAlign: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center', // Ensures content is centered properly
                            height: '100%', // Ensures both sections take up the same height
                        }}
                    >
                        <IconButton
                            disabled={
                                !token ||
                                (user &&
                                    user.userProfile.uuid ===
                                        upload.author.uuid)
                            }
                            onClick={handleHeartClick}
                            sx={{
                                padding: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                    boxShadow: 'none',
                                },
                            }}
                        >
                            <Favorite
                                sx={{
                                    color: isHearted
                                        ? theme.palette.error.main
                                        : theme.palette.text.primary,
                                }}
                            />
                        </IconButton>
                        <Typography
                            variant="body2"
                            color={TextCardPreviewDescriptionColor}
                            sx={{
                                cursor: 'default',
                            }}
                        >
                            {formatNumberToReadable(hearts)}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>

            {/* Tags Section */}
            <Card sx={{ padding: 2, textAlign: 'center', marginBottom: 1 }}>
                <Tags upload={upload} />
            </Card>

            {/* Version Section */}
            <div>
                <Button
                    id="version-button"
                    aria-controls={versionOpen ? 'version-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={versionOpen ? 'true' : undefined}
                    onClick={handleVersionClick}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    sx={{
                        textAlign: 'left',
                        padding: 1,
                        marginBottom: 1,
                        justifyContent: 'flex-start',
                        color: 'inherit',
                        textTransform: 'none',
                        gap: 2,
                    }}
                >
                    <DocumentScanner />
                    <Typography variant="body1">
                        VERSION: {upload.versions[selectedVersionIndex].version}
                        {' ('}
                        {convertSimple(
                            upload.versions[selectedVersionIndex].createdAt
                        ).toUpperCase()}
                        {')'}
                    </Typography>
                    {!isUploadPublic(upload, selectedVersionIndex) &&
                    timeLeftForReleasePublic !== null ? (
                        <Box sx={{ position: 'relative' }}>
                            <PatreonIcon />
                        </Box>
                    ) : null}
                </Button>
                <Menu
                    id="version-menu"
                    anchorEl={anchorElVersion}
                    open={versionOpen}
                    onClose={handleVersionClose}
                    MenuListProps={{
                        'aria-labelledby': 'version-button',
                    }}
                >
                    {[...upload.versions]
                        .sort((a, b) => b.version - a.version) // Sort versions in descending order
                        .map((ver, index) => (
                            <MenuItem
                                key={index}
                                onClick={() => {
                                    // Find the index of the selected version in the original array
                                    const selectedIndex =
                                        upload.versions.findIndex(
                                            (v) => v.version === ver.version
                                        );
                                    setSelectedVersionIndex(selectedIndex);
                                    handleVersionClose();
                                }}
                            >
                                VERSION {ver.version} {' ('}
                                {convertSimple(ver.createdAt).toUpperCase()}
                                {')'}
                                {!isVersionPublic(ver) ? (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            marginLeft: 2,
                                        }}
                                    >
                                        <Box>
                                            <PatreonIcon />
                                        </Box>
                                    </Box>
                                ) : null}
                            </MenuItem>
                        ))}
                </Menu>
            </div>
        </>
    );
};

export default ActionsArea;
