// src/components/modal/DeleteUploadModal.js

import React from 'react';
import { Link, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import CustomModal from '../universal/modals/CustomModal';

interface TermsModalProps {
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    isModalOpen: boolean;
    isMobile: boolean;
}

const TermsModal: React.FC<TermsModalProps> = ({
    isMobile,
    closeModal,
    isModalOpen,
}) => {
    const { theme } = useCustomTheme();
    return (
        <CustomModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            isMobile={isMobile}
            cancelLabel={'Close'}
        >
            {/* Modal content passed directly as children */}
            <Typography
                variant="h3"
                sx={{
                    marginBottom: 3,
                }}
                color={theme.palette.text.secondary}
            >
                TERMS OF SERVICE
            </Typography>
            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                Effective Date: 25 Feb 2025
            </Typography>

            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                Welcome to Discovery ("we," "our," or "us"). By accessing or
                using our website{' '}
                <Link
                    href="https://discovery.onl"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        cursor: 'pointer',
                        color: theme.palette.warning.main,
                        textDecoration: 'none', // Ensure underline is shown
                    }}
                >
                    https://discovery.onl
                </Link>{' '}
                (the "Site"), you agree to comply with and be bound by these
                Terms of Service ("Terms"). Please read these Terms carefully
                before using our Site. If you do not agree with any part of
                these Terms, you must not access or use the Site.
            </Typography>

            {/* 1. Acceptance of Terms */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                1. Acceptance of Terms
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                By accessing and using the Site, you agree to be bound by these
                Terms, as well as our Privacy Policy. We may update these Terms
                from time to time, and we will notify users of material changes
                by updating the "Effective Date" at the top of this page. It is
                your responsibility to check this page periodically for updates.
            </Typography>

            {/* 2. Account Registration */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                2. Account Registration
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                To access certain features on the Site, you may be required to
                create an account. You must provide accurate and complete
                information during registration, and you are responsible for
                maintaining the confidentiality of your account credentials. You
                agree to notify us immediately of any unauthorized use of your
                account.
            </Typography>

            {/* 3. Use of the Site */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                3. Use of the Site
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                You agree to use the Site only for lawful purposes and in
                accordance with these Terms. You may not use the Site to:
            </Typography>
            <ul>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Violate any local, state, national, or international law
                        or regulation.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Upload, post, or share any content that is unlawful,
                        defamatory, offensive, infringing, or otherwise harmful.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Interfere with or disrupt the operation of the Site or
                        its features.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Engage in any activity that could harm the
                        functionality, security, or reputation of the Site.
                    </Typography>
                </li>
            </ul>

            {/* 4. User-Generated Content */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                4. User-Generated Content
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                Users may submit content (e.g., mods, images, descriptions) to
                the Site. You retain ownership of the content you upload, but by
                submitting content, you grant us a non-exclusive, royalty-free,
                worldwide license to use, display, and distribute your content
                on the Site.
            </Typography>

            {/* 5. Prohibited Conduct */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                5. Prohibited Conduct
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                You agree not to engage in any of the following prohibited
                activities:
            </Typography>
            <ul>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Attempt to gain unauthorized access to the Site or its
                        systems.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Exploit, harass, or harm other users of the Site.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Use automated systems, such as bots or scrapers, to
                        collect data or interact with the Site.
                    </Typography>
                </li>
                <li>
                    <Typography
                        variant="body1"
                        color={theme.palette.text.secondary}
                    >
                        Impersonate any person or entity or misrepresent your
                        affiliation with any person or entity.
                    </Typography>
                </li>
            </ul>

            {/* 6. Disclaimer of Warranties */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                6. Disclaimer of Warranties
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                The Site is provided "as is" and "as available." We do not
                guarantee that the Site will be free from errors or
                interruptions. We disclaim all warranties, express or implied,
                including but not limited to warranties of merchantability or
                fitness for a particular purpose. We do not warrant that the
                Site will meet your expectations or be error-free.
            </Typography>

            {/* 7. Limitation of Liability */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                7. Limitation of Liability
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                To the fullest extent permitted by law, we shall not be liable
                for any indirect, incidental, special, consequential, or
                punitive damages, or any loss of data, profits, or business
                opportunities arising from your use or inability to use the
                Site.
            </Typography>

            {/* 8. Contact Us */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                8. Contact Us
            </Typography>
            <Typography
                variant="body1"
                sx={{ mb: 2 }}
                color={theme.palette.text.secondary}
            >
                If you have any questions about these Terms, please contact us
                at: discovery.onl.contact@gmail.com.
            </Typography>
        </CustomModal>
    );
};

export default TermsModal;
