// GameCover.tsx
import React from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Game, GamesGrouped, Upload } from '../../workers/ApiWorker';
import Tag from '../universal/chips/Tag';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';
import {
    SecondaryLightColor,
    TextCardPreviewDescriptionColor,
    TextDisabledColor,
    TextSecondaryColor,
} from '../../helpers/Themes';
import Card from '@mui/material/Card';
import { Download, Favorite, Save, Visibility } from '@mui/icons-material';
import { formatNumberToReadable } from '../../helpers/Numbers';

interface GameCoverProps {
    game: GamesGrouped;
    handleClick?: (value: string[]) => void;
}

const GameCover: React.FC<GameCoverProps> = ({ game, handleClick }) => {
    return (
        <Card
            sx={{
                width: '100%',
                height: '100%', // Make all cards take the same height
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                transition: 'transform 0.2s',
                '&:hover': {
                    filter: 'brightness(1.1)',
                    transition: 'all 0.06s ease',
                },
                backgroundColor: SecondaryLightColor,
            }}
            onClick={() => {
                if (handleClick) {
                    handleClick(game.uuids);
                }
            }}
        >
            {/* Image Section */}
            {game.image?.main ? (
                <CardMedia
                    component="img"
                    alt={game.name}
                    image={game.image.main}
                    sx={{
                        width: '100%',
                        height: 'auto',
                        objectFit: 'cover',
                        aspectRatio: '3/4', // Ensures consistent aspect ratio
                    }}
                />
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        height: '100%', // Match the height behavior of images
                        aspectRatio: '3/4',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: 1, // Ensures it stretches like the images
                    }}
                >
                    <Typography
                        variant="caption"
                        color={TextCardPreviewDescriptionColor}
                    >
                        NO IMAGE
                    </Typography>
                </Box>
            )}

            {/* Info Section (Game Name) */}
            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1, // Ensures consistent height and allows space to grow
                    justifyContent: 'space-between', // Pushes content to top and bottom
                    textAlign: 'left',
                    paddingLeft: 0.5,
                    paddingRight: 0.5,
                    paddingTop: 0.5,
                    marginBottom: -2.5,
                }}
            >
                {/* Other content can go here (e.g., Game name or description, if you have it) */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 0.5,
                        paddingTop: 0.5,
                        paddingBottom: 0.5,
                    }}
                >
                    <Typography variant="caption" color={TextSecondaryColor}>
                        {game.shortName}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {/* Downloads */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        <Download
                            fontSize="small"
                            sx={{ color: TextCardPreviewDescriptionColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextCardPreviewDescriptionColor}
                        >
                            {formatNumberToReadable(game.stats?.downloads || 0)}
                        </Typography>
                    </Box>

                    {/* Views */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        <Visibility
                            fontSize="small"
                            sx={{ color: TextCardPreviewDescriptionColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextCardPreviewDescriptionColor}
                        >
                            {formatNumberToReadable(game.stats?.views || 0)}
                        </Typography>
                    </Box>

                    {/* Likes */}
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 0.5,
                        }}
                    >
                        <Favorite
                            fontSize="small"
                            sx={{ color: TextCardPreviewDescriptionColor }}
                        />
                        <Typography
                            variant="caption"
                            color={TextCardPreviewDescriptionColor}
                        >
                            {formatNumberToReadable(game.stats?.hearts || 0)}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default GameCover;
