// SuccessAlert.tsx
import React from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface SuccessAlertInputProps {
    message: string;
}

const SuccessAlert: React.FC<SuccessAlertInputProps> = ({ message }) => {
    return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
            <Alert
                severity="success"
                variant="outlined"
                sx={{ cursor: 'default' }}
            >
                {message}
            </Alert>
        </Box>
    );
};

export default SuccessAlert;
