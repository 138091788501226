// GamesPage.tsx
import React, { useEffect, useState } from 'react';
import {
    bigScreenPadding,
    bigScreenWidth,
    fadeTimeout,
    getOverallPadding,
    TextCardPreviewDescriptionColor,
    TextSecondaryColor,
} from '../helpers/Themes';
import { Fade, Grid, Box, Typography, CardContent } from '@mui/material';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { Game, GamesGrouped, User } from '../workers/ApiWorker';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import LoadingPreviewCard from '../components/universal/cards/LoadingPreviewCard';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useNavigate } from 'react-router-dom';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoImage from '../components/upload/NoImage';
import GameCover from '../components/game/GameCover';
import GameCoverLoading from '../components/game/GameCoverLoading';
import { wait } from '@testing-library/user-event/dist/utils';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

interface GamesPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
    width?: number;
}

const GamesPage: React.FC<GamesPageProps> = ({
    isMobile,
    padding,
    user,
    width,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const navigate = useNavigate();

    const [loadingResults, setLoadingResults] = useState<boolean>(true);
    const [games, setGames] = useState<GamesGrouped[] | null>(null);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                setLoadingResults(true);
                const response = await apiWorker.getGames(true);

                await wait(500);
                // Sort games by name alphabetically
                const sortedGames = response.data.sort((a: Game, b: Game) =>
                    a.name.localeCompare(b.name)
                );

                // Group games by name
                const groupedGames: GamesGrouped[] = [];

                sortedGames.forEach((game) => {
                    const existingGroup = groupedGames.find(
                        (group) => group.name === game.name
                    );

                    if (existingGroup) {
                        existingGroup.uuids.push(game.uuid); // Add the uuid to the existing group
                        if (!existingGroup.system.includes(game.system)) {
                            existingGroup.system.push(game.system); // Add system if it's not already in the list
                        }

                        existingGroup.stats.hearts += game.stats?.hearts || 0;
                        existingGroup.stats.downloads +=
                            game.stats?.downloads || 0;
                        existingGroup.stats.views += game.stats?.views || 0;
                    } else {
                        groupedGames.push({
                            name: game.name,
                            shortName: game.shortName,
                            uuids: [game.uuid],
                            image: game.image,
                            system: [game.system],
                            stats: {
                                hearts: game.stats?.hearts || 0,
                                downloads: game.stats?.downloads || 0,
                                views: game.stats?.views || 0,
                            },
                        });
                    }
                });

                setGames(groupedGames);
            } catch (error) {
                console.error('Error fetching games:', error);
                setGames(null);
            } finally {
                setLoadingResults(false);
            }
        };

        fetchGames();
    }, [apiWorker]);

    const handleClickValue = (uuids: string[]) => {
        localStorage.removeItem('searchParams');
        const query = uuids.join(','); // Join the uuids array into a comma-separated string
        const searchId = new Date().getTime();

        if (query) {
            navigate(
                `/search?games=${encodeURIComponent(query)}&searchId=${searchId}`,
                { replace: true }
            );
        }
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden', padding: 2 }}>
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(
                            isMobile,
                            padding,
                            width && width >= bigScreenWidth
                                ? bigScreenPadding
                                : undefined
                        ),
                        paddingRight: getOverallPadding(
                            isMobile,
                            padding,
                            width && width >= bigScreenWidth
                                ? bigScreenPadding
                                : undefined
                        ),
                    }}
                >
                    <PageTopSection
                        isMobile={isMobile}
                        padding={padding}
                        hideSeperator={true}
                        gapSize={'small'}
                    />
                    {loadingResults ? (
                        <Fade in={true} timeout={fadeTimeout}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ marginBottom: 10 }}
                            >
                                {Array.from({
                                    length: 100,
                                }).map((_, index) => (
                                    <Grid
                                        item
                                        xs={6}
                                        sm={4}
                                        md={3}
                                        lg={2}
                                        xl={1.5}
                                        key={index}
                                        sx={{ display: 'flex' }} // Ensures all grid items stretch equally
                                    >
                                        <GameCoverLoading />
                                    </Grid>
                                ))}
                            </Grid>
                        </Fade>
                    ) : games && games.length > 0 ? (
                        <Grid container spacing={2} sx={{ marginBottom: 10 }}>
                            {games.map((game) => (
                                <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    lg={2}
                                    xl={1.5}
                                    key={game.name}
                                    sx={{ display: 'flex' }} // Ensures all grid items stretch equally
                                >
                                    <Fade in={true} timeout={fadeTimeout}>
                                        <div
                                            style={{
                                                margin: 0,
                                                padding: 0,
                                                width: '100%',
                                                height: '100%',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <GameCover
                                                game={game}
                                                handleClick={handleClickValue}
                                            />
                                        </div>
                                    </Fade>
                                </Grid>
                            ))}
                        </Grid>
                    ) : (
                        <NoResultsFound />
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default GamesPage;
