import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardContent, Skeleton } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import Box from '@mui/material/Box';
import { Block } from '@mui/icons-material';

interface LoadingPreviewCardProps {
    invisible?: boolean;
}

const LoadingPreviewCard: React.FC<LoadingPreviewCardProps> = ({
    invisible,
}) => {
    const { theme } = useCustomTheme();

    return (
        <Card
            sx={{
                cursor: undefined,
                transition: 'transform 0.2s',
                visibility: invisible ? 'hidden' : undefined,
            }}
        >
            <Skeleton
                variant="rectangular"
                sx={{
                    width: '100%',
                    maxWidth: 5000, // Optional: Adjust the max width for the card

                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <CardMedia
                    sx={{
                        height: 210, // Set the height you want for the CardMedia
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Arrange items in a column
                            justifyContent: 'center', // Center vertically
                            alignItems: 'center', // Center horizontally
                            height: '100%', // Take full height of CardMedia
                        }}
                    >
                        <Block
                            sx={{
                                fontSize: 60,
                                color: theme.palette.error.main,
                            }}
                        />{' '}
                        {/* Stop sign icon */}
                        <Typography variant="h2" sx={{ marginTop: 1 }}>
                            LOADING
                        </Typography>
                    </Box>
                </CardMedia>

                <CardContent
                    sx={{
                        position: 'relative',
                        overflow: 'hidden',
                        backgroundColor: theme.palette.secondary.main,
                        height: '105px',
                    }}
                >
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <Typography
                            textAlign={'left'}
                            variant="caption"
                            sx={{
                                color: theme.palette.warning.main,
                                display: 'block', // Ensure it behaves as a block-level element
                                textAlign: 'left', // Explicitly set text alignment
                                marginBottom: 0, // Reset margin if necessary
                            }}
                        >
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-start',
                                    }}
                                >
                                    LOADING
                                </div>
                            </>
                        </Typography>
                    </div>
                </CardContent>
            </Skeleton>
        </Card>
    );
};

export default LoadingPreviewCard;
