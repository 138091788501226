// HomePage.tsx
import React from 'react';
import { CardContent, Grid, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { Upload } from '../../workers/ApiWorker';
import Card from '@mui/material/Card';

interface DescriptionAreaProps {
    upload: Upload;
}

const DescriptionArea: React.FC<DescriptionAreaProps> = ({ upload }) => {
    const { theme } = useCustomTheme();

    function sanitizeQuillHtml(data: string) {
        // Convert opening <ol><li data-list="bullet"> to <ul><li>
        let sanitizedHtml = data.replace(
            /<ol><li data-list="bullet">/g,
            '<ul><li>'
        );

        // Replace closing tags only for the unordered lists (bullets)
        sanitizedHtml = sanitizedHtml.replace(/<\/li><\/ol>/g, '</li></ul>');

        return sanitizedHtml;
    }

    return (
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Card
                sx={{
                    marginTop: 2,
                    // We might need to remove the below line as its laggy and doesn't work on all browsers
                    //backdropFilter: 'blur(30px)',
                    backgroundColor: 'transparent',
                    cursor: 'default',
                }}
            >
                <CardContent>
                    <div style={{ position: 'relative', zIndex: 2 }}>
                        <Typography
                            sx={{
                                color: theme.palette.text.secondary,
                                marginBottom: -1,
                                '& h1, & h2, & h3, & h4, & h5, & h6, & p, & ol, & ul':
                                    {
                                        margin: 0,
                                    },
                            }}
                            dangerouslySetInnerHTML={{
                                __html: sanitizeQuillHtml(upload.description),
                            }}
                        ></Typography>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default DescriptionArea;
