// HomePage.tsx
import React, { useEffect, useState } from 'react';
import { AvatarGroup, Fade, Typography } from '@mui/material';
import {
    Upload,
    UploadTypeNames,
    User,
    UserAccountTypes,
    UserProfile,
} from '../workers/ApiWorker';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { useApi } from '../contexts/ApiContext';
import Box from '@mui/material/Box';
import Filters from '../components/filters/Filters';
import { searchFilters } from '../helpers/SearchFilters';
import Carousel from '../components/carousel/Carosel';
import Stack from '@mui/material/Stack';
import UserAvatar from '../components/user/UserAvatar';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import NoResultsFound from '../components/universal/loader/NoResultsFound';
import { getUploadTypeByName } from '../helpers/Uploads';

interface HomePageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const HomePage: React.FC<HomePageProps> = ({ isMobile, padding, user }) => {
    const { uploadTypes, token } = useApi();

    const defaultCount = 20;

    const apiWorker = useWorker(createApiWorker);

    const [selectedFilter, setSelectedFilter] = useState(
        searchFilters()[0].value
    );

    const userAvatarCount = 4;

    const [loadingAllUploads, setLoadingAllUploads] = useState<boolean>(true);
    const [loadingCharacters, setLoadingCharacters] = useState<boolean>(true);
    const [loadingCharactersWomen, setLoadingCharactersWomen] =
        useState<boolean>(true);
    const [loadingArenas, setLoadingArenas] = useState<boolean>(true);
    const [loadingAuthors, setLoadingAuthors] = useState<boolean>(true);

    const [characterWomenUploads, setCharacterWomenUploads] = useState<
        Upload[]
    >([]);

    const [characterUploads, setCharacterUploads] = useState<Upload[]>([]);

    const [arenaUploads, setArenaUploads] = useState<Upload[]>([]);

    const [allUploads, setAllUploads] = useState<Upload[]>([]);

    const [authors, setAuthors] = useState<UserProfile[]>([]);
    const [selectedAuthors, setSelectedAuthors] = useState<UserProfile[]>([]);

    const handleReset = async () => {
        setAuthors([]);
        setLoadingAuthors(true);
        setLoadingCharacters(true);
        setLoadingCharactersWomen(true);
        setLoadingAllUploads(true);
        setLoadingArenas(true);
        setCharacterUploads([]);
        setCharacterWomenUploads([]);
        setArenaUploads([]);
        setAllUploads([]);
    };

    const handleSelectedFilter = async (value: string) => {
        await handleReset();
        setSelectedFilter(value);
    };

    const handleGetUploads = async () => {
        await handleReset();

        if (uploadTypes) {
            const allUploads = [];
            const characters = [];
            const women = [];
            const arenas = [];

            const response = await apiWorker.getUploadsDirection(false, token, {
                count: defaultCount,
                status: selectedFilter,
            });
            allUploads.push(...response.data);
            characters.push(...response.data);

            // Get Characters
            const uploadTypeCharacterUuid = await getUploadTypeByName(
                uploadTypes,
                UploadTypeNames.CHARACTER
            );

            const uploadTypeArenaUuid = await getUploadTypeByName(
                uploadTypes,
                UploadTypeNames.ARENA
            );

            if (uploadTypeCharacterUuid) {
                const responseWomen = await apiWorker.getUploadsDirection(
                    false,
                    token,
                    {
                        count: defaultCount,
                        type: uploadTypeCharacterUuid,
                        status: selectedFilter,
                        additionalTypeInformation: 'women',
                    }
                );

                allUploads.push(...responseWomen.data);
                women.push(...responseWomen.data);
            } else {
                console.warn('Upload type not found - Character');
            }

            if (uploadTypeArenaUuid) {
                const responseArena = await apiWorker.getUploadsDirection(
                    false,
                    token,
                    {
                        count: defaultCount,
                        type: uploadTypeArenaUuid,
                        status: selectedFilter,
                    }
                );
                allUploads.push(...responseArena.data);
                arenas.push(...responseArena.data);
            } else {
                console.warn('Upload type not found - Arena');
            }

            setCharacterUploads(characters);
            setArenaUploads(arenas);
            setCharacterWomenUploads(women);
            setAllUploads(allUploads);
            setLoadingAllUploads(false);
            setLoadingCharacters(false);
            setLoadingCharactersWomen(false);
            setLoadingArenas(false);
        }
    };

    const getRandomAuthors = (authors: UserProfile[], count: number) => {
        // Shuffle array (Fisher-Yates Shuffle)
        const shuffled = [...authors].sort(() => 0.5 - Math.random());
        // Get up to the specified number of authors (or the length of authors array if smaller)
        return shuffled.slice(0, Math.min(count, authors.length));
    };

    useEffect(() => {}, []);

    useEffect(
        () => {
            handleGetUploads().catch(console.error);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedFilter, uploadTypes]
    );

    // Authors
    useEffect(() => {
        //  if (!loadingCharactersWomen && !loadingArenas && !loadingCharacters) {
        const updateAuthors = (uploads: Upload[]) => {
            const uniqueAuthors = new Map<string, UserProfile>();

            // Loop through each upload and add author to the Map (which keeps only unique values)
            uploads.forEach((upload) => {
                const { uuid } = upload.author;
                if (!uniqueAuthors.has(uuid)) {
                    uniqueAuthors.set(uuid, upload.author);
                }
            });

            const allUniqueAuthors = Array.from(uniqueAuthors.values());

            // Convert map values to an array and update state
            setSelectedAuthors(
                getRandomAuthors(allUniqueAuthors, userAvatarCount)
            );
            setAuthors(allUniqueAuthors);
        };

        // If there are uploads, update authors
        if (allUploads.length > 0) {
            updateAuthors(allUploads);
        } else {
            setAuthors([]);
        }
        setLoadingAuthors(false);
        // }
    }, [loadingAllUploads]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
            <PageTopSection
                isMobile={isMobile}
                padding={padding}
                advancedContent={
                    <>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: isMobile ? 20 : 0, // Align the filters to the bottom of the container
                                left: 0,
                                right: 0, // Ensures it stretches to both ends
                            }}
                        >
                            <Filters
                                isMobile={isMobile}
                                filters={searchFilters}
                                selectedFilter={selectedFilter}
                                handleSelectedFilter={handleSelectedFilter}
                                padding={padding}
                                user={user}
                                isLoading={loadingAllUploads}
                            />
                        </Box>

                        {/* HELLO text on the right-hand side */}
                        <Box
                            sx={{
                                position: 'absolute',
                                top: -30, // Vertically centers the text
                                right: 20, // Adjust as needed to place it slightly from the edge
                                transform: 'translateY(-50%)', // Ensures vertical centering
                                paddingRight: padding,
                            }}
                        >
                            {!loadingAuthors &&
                            !isMobile &&
                            authors.length > 0 ? (
                                <Fade in={true} timeout={fadeTimeout}>
                                    <div>
                                        <AvatarGroup>
                                            {authors.length > 0 &&
                                            selectedAuthors.length > 0
                                                ? selectedAuthors.map(
                                                      (author, index) => (
                                                          <UserAvatar
                                                              key={index}
                                                              backup={
                                                                  author.username
                                                              }
                                                              dimensions={36}
                                                              border={false}
                                                              userProfile={
                                                                  author
                                                              }
                                                              tooltip={true}
                                                          />
                                                      )
                                                  )
                                                : null}

                                            {/* Show "+X" UserAvatar if there are more authors than the visible count */}
                                            {authors.length - userAvatarCount >
                                                0 && (
                                                <UserAvatar
                                                    backup={`+${authors.length - userAvatarCount}`}
                                                    dimensions={36}
                                                    border={false}
                                                    total={true}
                                                />
                                            )}
                                        </AvatarGroup>
                                    </div>
                                </Fade>
                            ) : null}
                        </Box>
                    </>
                }
            />
            <Box
                sx={{
                    paddingLeft: getOverallPadding(isMobile, padding),
                    paddingRight: getOverallPadding(isMobile, padding),
                    marginBottom: isMobile ? 10 : undefined,
                }}
            >
                <Stack
                    sx={{
                        textAlign: 'left',
                    }}
                >
                    {loadingAllUploads ||
                    (!loadingCharacters && characterUploads.length > 0) ? (
                        <div>
                            <Carousel
                                slidesPerView={4}
                                data={characterUploads}
                                type={UploadTypeNames.CHARACTER}
                                index={1}
                                isMobile={isMobile}
                                user={user}
                            />
                        </div>
                    ) : (
                        <NoResultsFound />
                    )}

                    <Typography
                        variant={'h6'}
                        sx={{
                            marginBottom: 2,
                            cursor: 'default',
                        }}
                    >
                        ARENAS
                    </Typography>
                    {loadingArenas ||
                    (!loadingArenas && arenaUploads.length > 0) ? (
                        <Carousel
                            slidesPerView={2}
                            data={arenaUploads}
                            type={UploadTypeNames.ARENA}
                            index={2}
                            isMobile={isMobile}
                            user={user}
                        />
                    ) : (
                        <NoResultsFound />
                    )}

                    <Typography
                        variant={'h6'}
                        sx={{
                            marginBottom: 2,
                            cursor: 'default',
                        }}
                    >
                        WOMEN CHARACTERS
                    </Typography>
                    {loadingArenas ||
                    (!loadingCharactersWomen &&
                        characterWomenUploads.length > 0) ? (
                        <Carousel
                            slidesPerView={4}
                            data={characterWomenUploads}
                            type={UploadTypeNames.CHARACTER}
                            index={3}
                            isMobile={isMobile}
                            user={user}
                        />
                    ) : (
                        <NoResultsFound />
                    )}
                </Stack>
            </Box>
        </Box>
    );
};

export default HomePage;
