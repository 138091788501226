import React from 'react';
import { OrangeColor } from '../../../helpers/Themes';
import { SiPatreon } from 'react-icons/si';
import Box from '@mui/material/Box';

const PatreonIcon: React.FC<{}> = ({}) => {
    return (
        <Box
            sx={{
                width: 28,
                height: 28,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: OrangeColor, // Circle color
                borderRadius: '50%', // Makes it a circle
            }}
        >
            <SiPatreon style={{ color: 'white', fontSize: 18 }} />{' '}
            {/* Keeps the icon color unchanged */}
        </Box>
    );
};

export default PatreonIcon;
