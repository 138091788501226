import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Link, Fade } from '@mui/material';
import logo from '../images/discovery-logo.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';
import { useCustomTheme } from '../contexts/ThemeContext';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import {
    POST_FORGOT_PASSWORD_FAILED,
    POST_FORGOT_PASSWORD_SUCCESS,
    POST_RESET_PASSWORD_FAILED,
    POST_RESET_PASSWORD_SUCCESS,
    POST_VERIFY_ACCOUNT_FAILED,
    POST_VERIFY_ACCOUNT_SUCCESS,
} from '../helpers/Messages';
import ReCAPTCHA from 'react-google-recaptcha';
import { Upload } from '../workers/ApiWorker';
import { useApi } from '../contexts/ApiContext';
import Cookies from 'universal-cookie';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const VerifyAccountPage: React.FC = () => {
    const cookies = new Cookies();
    const { theme } = useCustomTheme();
    const navigate = useNavigate();
    const { token } = useApi();
    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<string>('');

    const [paramsError, setParamsError] = React.useState<boolean>(false);

    const [waiting, setWaiting] = React.useState<boolean>(true);

    // Get search params from URL
    const [searchParams] = useSearchParams();
    const verifyToken = searchParams.get('token'); // Get 'code' from query string

    const [captchaValue, setCaptchaValue] = useState<string | null>(null);

    const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };
    //  const email = searchParams.get('email');

    useEffect(() => {
        const verifyAccount = async () => {
            if (!verifyToken || !token) {
                setParamsError(true);
            } else {
                try {
                    await apiWorker.verifyAccount(token, verifyToken);
                    cookies.remove('resendVerificationCache');
                    setSuccess(POST_VERIFY_ACCOUNT_SUCCESS);
                } catch (error) {
                    setError(POST_VERIFY_ACCOUNT_FAILED);
                    setShowError(true);
                }
                setWaiting(false);
            }
        };

        verifyAccount().catch(console.error);
    }, [verifyToken, token]); // Run whenever `code` changes or is first fetched

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px',
                        minHeight: '350px',
                    }}
                >
                    {/* Logo */}
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: '80px', marginBottom: '2rem' }}
                    />

                    {paramsError ? (
                        <>
                            <Typography
                                variant="h2"
                                sx={{ fontWeight: 700, marginBottom: '1rem' }}
                            >
                                ERROR
                            </Typography>

                            {/* Subtitle */}
                            <Typography
                                variant="body2"
                                sx={{
                                    marginBottom: '2rem',
                                    color: theme.palette.text.disabled,
                                }}
                            >
                                <Link
                                    href="/"
                                    underline="hover"
                                    variant="body2"
                                    sx={{
                                        color: theme.palette.text.disabled,
                                    }}
                                >
                                    <i>
                                        Something strange has happened, please
                                        click <b>here</b> to return to the home
                                        page.
                                    </i>
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <>
                            {waiting && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        marginBottom: '2rem',
                                        color: theme.palette.text.disabled,
                                    }}
                                >
                                    please wait a moment whilst we verify your
                                    account
                                </Typography>
                            )}

                            {/* Subtitle */}

                            {showError && <ErrorAlert message={error} />}

                            {success !== '' && (
                                <>
                                    <SuccessAlert message={success} />
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            padding: '0.8rem',
                                            marginBottom: 1.8,
                                        }}
                                        onClick={() => {
                                            navigate('/');
                                        }}
                                    >
                                        HOME
                                    </Button>
                                </>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default VerifyAccountPage;
