import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Typography,
    Link,
    FormControlLabel,
    Checkbox,
    Fade,
} from '@mui/material';
import logo from '../images/discovery-logo.png';
import { useApi } from '../contexts/ApiContext';
import { useNavigate } from 'react-router-dom';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';
import { useCustomTheme } from '../contexts/ThemeContext';
import ReCAPTCHA from 'react-google-recaptcha';
import { POST_REGISTER_SUCCESS } from '../helpers/Messages';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import TermsModal from '../components/modal/TermsModal';
import PolicyModal from '../components/modal/PolicyModal';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

// Validation Schema
const validationSchema = Yup.object({
    email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string()
        .min(10, 'Password should be at least 10 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Password must contain at least 1 uppercase, 1 lowercase, and 1 number'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
    username: Yup.string()
        .min(3, 'Display name must be at least 3 characters')
        .max(25, 'Display name must be at most 25 characters')
        .matches(
            /^[a-zA-Z0-9 _\-!?]*$/,
            'Display name can only contain letters, numbers, spaces, and - _ ! ?'
        )
        .required('Display name is required'),
    confirmation: Yup.boolean().oneOf(
        [true],
        'You must accept the terms and conditions'
    ),
});

interface RegisterPageProps {
    isMobile: boolean;
}

const RegisterPage: React.FC<RegisterPageProps> = ({ isMobile }) => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { theme } = useCustomTheme();
    const navigate = useNavigate();
    const { storeTokenAndUser } = useApi();
    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<string>('');
    const [isTermsModalOpen, setIsTermsModalOpen] =
        React.useState<boolean>(false);
    const [isPolicyModalOpen, setIsPolicyModalOpen] =
        React.useState<boolean>(false);

    const [captchaValue, setCaptchaValue] = useState<string | null>(null);

    const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };

    const showTermsModal = () => {
        setIsTermsModalOpen(true);
    };

    const closeTermsModal = () => {
        setIsTermsModalOpen(false);
    };

    const showPolicyModal = () => {
        setIsPolicyModalOpen(true);
    };

    const closePolicyModal = () => {
        setIsPolicyModalOpen(false);
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px',
                        minHeight: '500px', // Ensures the form has a consistent height
                    }}
                >
                    {/* Logo */}
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: '80px', marginBottom: '2rem' }}
                    />

                    {/* Title */}
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            marginBottom: '0rem',
                            cursor: 'default',
                        }}
                    >
                        CREATE ACCOUNT
                    </Typography>

                    {/* Subtitle */}
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: '2rem',
                            color: theme.palette.text.disabled,
                            cursor: 'default',
                        }}
                    >
                        join the discovery platform
                    </Typography>

                    {showError && <ErrorAlert message={error} />}

                    {success !== '' ? (
                        <>
                            <SuccessAlert message={success} />
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                sx={{
                                    padding: '0.8rem',
                                    marginBottom: 1.8,
                                }}
                                onClick={() => {
                                    navigate('/login');
                                }}
                            >
                                LOGIN
                            </Button>
                        </>
                    ) : (
                        <>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                    confirmPassword: '',
                                    username: '',
                                    confirmation: false,
                                }}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    setShowError(false);
                                    if (
                                        captchaValue !== null &&
                                        recaptchaRef.current
                                    ) {
                                        try {
                                            await apiWorker.createUser(
                                                values.email,
                                                values.password,
                                                values.username,
                                                captchaValue
                                            );
                                            setSuccess(POST_REGISTER_SUCCESS);
                                            resetForm();
                                        } catch (error) {
                                            setError(
                                                axios.isAxiosError(error) &&
                                                    error.response?.data
                                                    ? error.response.data
                                                          .message
                                                    : unknownError().message
                                            );
                                            setShowError(true);
                                            setCaptchaValue(null);
                                            recaptchaRef.current.reset();
                                        }
                                    }
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    isSubmitting,
                                    handleBlur,
                                    isValid,
                                }) => (
                                    <Form style={{ width: '100%' }}>
                                        <TextInput
                                            id="username"
                                            label="Display name *"
                                            value={values.username}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            hasSubmitted={isSubmitting}
                                            touched={touched.username}
                                            errors={errors.username}
                                            enableLabel={true}
                                        />

                                        <TextInput
                                            id="email"
                                            label="Email *"
                                            value={values.email}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            hasSubmitted={isSubmitting}
                                            touched={touched.email}
                                            errors={errors.email}
                                            enableLabel={true}
                                        />

                                        <TextInput
                                            id="password"
                                            label="Password *"
                                            value={values.password}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            hasSubmitted={isSubmitting}
                                            touched={touched.password}
                                            errors={errors.password}
                                            type="password"
                                            enableLabel={true}
                                        />

                                        <TextInput
                                            id="confirmPassword"
                                            label="Confirm Password *"
                                            value={values.confirmPassword}
                                            handleChange={handleChange}
                                            handleBlur={handleBlur}
                                            hasSubmitted={isSubmitting}
                                            touched={touched.confirmPassword}
                                            errors={errors.confirmPassword}
                                            type="password"
                                            enableLabel={true}
                                        />

                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    id="confirmation"
                                                    name="confirmation"
                                                    checked={
                                                        values.confirmation
                                                    }
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    disabled={isSubmitting}
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.main,
                                                    }}
                                                />
                                            }
                                            label={
                                                <Typography
                                                    variant="body2"
                                                    sx={{
                                                        color: theme.palette
                                                            .text.secondary,
                                                    }}
                                                >
                                                    I agree to the&nbsp;
                                                    <Link
                                                        sx={{
                                                            color: theme.palette
                                                                .warning.main,
                                                            cursor: 'pointer', // Ensure it looks clickable
                                                        }}
                                                        onClick={showTermsModal} // Removed parentheses
                                                    >
                                                        Terms of Service
                                                    </Link>
                                                    &nbsp;and&nbsp;
                                                    <Link
                                                        sx={{
                                                            color: theme.palette
                                                                .warning.main,
                                                            cursor: 'pointer', // Ensure it looks clickable
                                                        }}
                                                        onClick={
                                                            showPolicyModal
                                                        } // Removed parentheses
                                                    >
                                                        Privacy Policy
                                                    </Link>
                                                </Typography>
                                            }
                                        />

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center', // Centers horizontally
                                                alignItems: 'center', // Centers vertically
                                                marginTop: 2,
                                            }}
                                        >
                                            <ReCAPTCHA
                                                ref={recaptchaRef}
                                                sitekey={
                                                    process.env
                                                        .REACT_APP_RECAPTCHA_SITE_KEY as string
                                                }
                                                onChange={handleCaptchaChange}
                                            />
                                        </Box>

                                        <Button
                                            fullWidth
                                            type="submit"
                                            disabled={
                                                isSubmitting ||
                                                captchaValue === null ||
                                                !isValid
                                            }
                                            variant="contained"
                                            sx={{
                                                padding: '0.8rem',
                                                marginTop: 3,
                                                marginBottom: 1.8,
                                            }}
                                        >
                                            CREATE
                                        </Button>
                                    </Form>
                                )}
                            </Formik>

                            {/* Login Link */}
                            <Typography variant="body2">
                                <Link
                                    href="/login"
                                    underline="hover"
                                    variant="body2"
                                    sx={{ color: theme.palette.warning.main }}
                                >
                                    <i>Already a member?</i>{' '}
                                    <i>
                                        <b>Login!</b>
                                    </i>
                                </Link>
                            </Typography>

                            <TermsModal
                                closeModal={closeTermsModal}
                                isModalOpen={isTermsModalOpen}
                                isMobile={isMobile}
                            />

                            <PolicyModal
                                closeModal={closePolicyModal}
                                isModalOpen={isPolicyModalOpen}
                                isMobile={isMobile}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default RegisterPage;
