import React, { useState, ChangeEvent, useMemo } from 'react';
import { alpha, FormControl, FormHelperText, Grid } from '@mui/material';
import LabelInput from './LabelInput';
import ReactQuill from 'react-quill-new';
import { styled } from '@mui/system';
import 'react-quill-new/dist/quill.snow.css';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import {
    CardColor,
    DropDownHighlightColor,
    InputHoverBackgroundColor,
    InputHoverBorderColor,
    OrangeColor,
} from '../../../helpers/Themes';

interface RichTextInputProps {
    id: string;
    label: string;
    handleChange: (field: string, value: any) => void; // Updated
    handleBlur: (e: ChangeEvent<any>) => void;
    hasSubmitted: boolean;
    errors?: string;
    value?: string;
    type?: React.InputHTMLAttributes<unknown>['type'];
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
    submitForm?: () => Promise<void>;
    touchedFormik?: boolean;
}

const RichTextInput: React.FC<RichTextInputProps> = ({
    id,
    label,
    handleChange,
    hasSubmitted,
    errors,
    value,
    isMobile,
    caption,
    submitForm,
    touchedFormik,
}) => {
    const { theme } = useCustomTheme();

    const [onFocusCount, setOnFocusCount] = React.useState<number>(0);
    const [onBlur, setOnBlur] = useState<boolean>(false);
    const [onFocus, setOnFocus] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const modules = useMemo(
        () => ({
            toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
            ],
        }),
        []
    );

    const formats = useMemo(
        () => ['header', 'bold', 'italic', 'underline', 'strike', 'list'],
        []
    );

    const StyledQuillWrapper = useMemo(
        () =>
            styled('div')({
                '.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options':
                    {
                        border: 'none',
                        paddingTop: 8,
                        paddingBottom: 8,
                        paddingRight: 0,
                        paddingLeft: 0,
                        margin: '0',
                        borderRadius: '0px',
                    },
                '.ql-snow .ql-picker-options': {
                    backgroundColor: theme.palette.background.paper,
                },
                '.ql-snow.ql-toolbar .ql-picker-item:hover': {
                    color: theme.palette.text.secondary,
                    backgroundColor: DropDownHighlightColor,
                },
                '.ql-snow .ql-fill': {
                    fill: theme.palette.text.secondary,
                },
                '.ql-snow .ql-picker': {
                    color: theme.palette.text.secondary,
                },
                '.ql-snow .ql-stroke': {
                    stroke: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar button.ql-active .ql-fill': {
                    fill: theme.palette.primary.main,
                },
                '.ql-snow.ql-toolbar button.ql-active .ql-stroke': {
                    stroke: theme.palette.primary.main,
                },
                '.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke': {
                    stroke: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill': {
                    fill: theme.palette.text.secondary,
                },
                '.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label': {
                    borderColor: undefined,
                },
                '.ql-snow.ql-toolbar .ql-picker-label:hover': {
                    color: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar .ql-picker-item.ql-selected': {
                    color: theme.palette.text.secondary,
                },
                '.ql-snow .ql-picker.ql-expanded .ql-picker-label': {
                    color: theme.palette.text.secondary,
                    border: 'none',
                },
                '.ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke': {
                    stroke: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar .ql-picker-label.ql-active': {
                    color: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke': {
                    stroke: theme.palette.text.secondary,
                },
                '.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke': {
                    stroke: theme.palette.text.secondary,
                },
                // Apply top radius to the toolbar
                '&:focus-within': {
                    '.ql-toolbar.ql-snow, .ql-container.ql-snow': {
                        borderColor: undefined,
                        boxShadow: undefined,
                    },
                },
                '.ql-toolbar.ql-snow': {
                    backgroundColor: CardColor,
                    borderTopRightRadius: '3px',
                    borderTopLeftRadius: '3px',
                    borderRight: '1px solid rgba(41, 50, 70, 0)',
                    borderLeft: '1px solid rgba(41, 50, 70, 0)',
                    borderTop: '1px solid rgba(41, 50, 70, 0)',
                    borderBottom: '1px solid rgba(41, 50, 70, 0.15)', // Only bottom border
                },
                '.ql-container.ql-snow': {
                    borderBottomRightRadius: '4px',
                    borderBottomLeftRadius: '4px',
                    border: '1px solid rgba(41, 50, 70, 0)',
                },
                '&:focus-within:hover .ql-toolbar.ql-snow, &:focus-within:hover .ql-container.ql-snow':
                    {
                        borderColor: undefined,
                        boxShadow: undefined,
                    },

                '&:hover .ql-toolbar.ql-snow, &:hover .ql-container.ql-snow': {
                    borderColor: undefined,
                },
            }),
        [] // eslint-disable-line react-hooks/exhaustive-deps
    );

    const style = {
        backgroundColor: isHovered
            ? InputHoverBackgroundColor
            : 'rgba(41, 50, 70, 0.15)',
        color: theme.palette.text.secondary,
        border: onFocus
            ? `2px solid ${theme.palette.primary.main}`
            : isHovered
              ? `1px solid ${InputHoverBorderColor}`
              : errors && touched
                ? `1px solid ${theme.palette.error.main}`
                : errors && touchedFormik
                  ? `1px solid ${theme.palette.error.main}`
                  : hasSubmitted
                    ? `1px solid ${theme.palette.text.disabled}`
                    : `1px solid rgb(41, 50, 70, 0.15)`,
        // boxShadow: onFocus
        //     ? `inset 0 0 0 2px ${theme.palette.primary.main}`
        //     : isHovered
        //       ? `inset 0 0 0 1px ${theme.palette.text.secondary}`
        //       : errors && touched
        //         ? `inset 0 0 0 1px ${theme.palette.error.main}`
        //         : errors && touchedFormik
        //           ? `inset 0 0 0 1px ${theme.palette.error.main}`
        //           : hasSubmitted
        //             ? `inset 0 0 0 1px ${theme.palette.text.disabled}`
        //             : `inset 0 0 0 1px rgb(41, 50, 70, 0.15)`,
        borderTopLeftRadius: '4px',
        borderTopRightRadius: '4px',
        borderBottomLeftRadius: '4px',
        borderBottomRightRadius: '4px',
    };

    const handleInputChange = async (newValue?: string) => {
        handleChange(id, newValue); // Manually trigger Formik's field update
        if (submitForm) {
            await submitForm();
        }
    };

    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'center'}
                spacing={2}
            >
                <LabelInput
                    label={label}
                    isMobile={isMobile}
                    caption={caption}
                />

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : 9.5}
                    lg={9.8}
                    xl={10.25}
                >
                    <StyledQuillWrapper>
                        {useMemo(
                            () => (
                                <div
                                    onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    <ReactQuill
                                        readOnly={hasSubmitted}
                                        modules={modules}
                                        formats={formats}
                                        value={value}
                                        onFocus={() => {
                                            if (!hasSubmitted) {
                                                setTouched(true);
                                                setOnFocus(true);
                                                setOnBlur(true);
                                            }
                                        }}
                                        onBlur={() => {
                                            setOnFocusCount(onFocusCount + 1);
                                            if (value === '') {
                                                handleInputChange('').catch(
                                                    console.error
                                                );
                                            }

                                            if (!hasSubmitted) {
                                                setOnBlur(true);
                                                setOnFocus(false);
                                            }
                                        }}
                                        onChange={(value) => {
                                            const isEmptyContent =
                                                value === '<p><br></p>' ||
                                                value === '<h1><br></h1>' ||
                                                value === '<h2><br></h2>';

                                            setOnBlur(true);

                                            // If the content is empty (matches one of the empty patterns)
                                            if (!isEmptyContent) {
                                                handleInputChange(value).catch(
                                                    console.error
                                                );
                                            } else {
                                                handleInputChange(
                                                    undefined
                                                ).catch(console.error); // Treat it as empty
                                            }
                                        }}
                                        style={style}
                                    />
                                </div>
                            ),
                            [style] // eslint-disable-line react-hooks/exhaustive-deps
                        )}
                    </StyledQuillWrapper>
                    {(touched && errors && onBlur && onFocusCount > 0) ||
                    (touchedFormik && errors) ? (
                        <FormHelperText
                            sx={{ color: theme.palette.error.main }}
                        >
                            {errors}
                        </FormHelperText>
                    ) : null}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default RichTextInput;
