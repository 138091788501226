import React from 'react';
import IconButton from '@mui/material/IconButton';
import { FaDiscord, FaXTwitter } from 'react-icons/fa6';
import { SiPatreon } from 'react-icons/si';
import { useCustomTheme } from '../contexts/ThemeContext';
import { FaBluesky } from 'react-icons/fa6';
import { alpha } from '@mui/material';
import { FaFacebook, FaPaypal, FaYoutube } from 'react-icons/fa';
import Chip from '@mui/material/Chip';

export interface SocialItemLinksUserProfilePros {
    link: string;
    icon: JSX.Element;
    name: string;
}

export interface SocialLinksUserProfilePros {
    twitter: SocialItemLinksUserProfilePros;
    patreon: SocialItemLinksUserProfilePros;
    facebook: SocialItemLinksUserProfilePros;
    paypal: SocialItemLinksUserProfilePros;
    youtube: SocialItemLinksUserProfilePros;
    bluesky: SocialItemLinksUserProfilePros;
    discord: SocialItemLinksUserProfilePros;
}

export const SocialLinksUserProfile: SocialLinksUserProfilePros = {
    twitter: {
        link: 'https://x.com/',
        icon: <FaXTwitter />,
        name: 'Twitter',
    },
    patreon: {
        link: 'https://www.patreon.com/',
        icon: <SiPatreon />,
        name: 'Patreon',
    },
    youtube: {
        link: 'https://www.youtube.com/@',
        icon: <FaYoutube />,
        name: 'YouTube',
    },
    facebook: {
        link: 'https://www.facebook.com/',
        icon: <FaFacebook />,
        name: 'FaceBook',
    },
    paypal: {
        link: 'https://paypal.me/',
        icon: <FaPaypal />,
        name: 'PayPal',
    },
    bluesky: {
        link: 'https://bsky.app/profile/',
        icon: <FaBluesky />,
        name: 'Bluesky',
    },
    discord: {
        link: 'https://discord.gg/',
        icon: <FaDiscord />,
        name: 'Discord',
    },
};

interface SocialProps {
    twitter?: string;
    discord?: string;
    patreon?: string;
    blueSky?: string;
    youtube?: string;
    paypal?: string;
    facebook?: string;
    buttonStyle?: 'button' | 'chip';
    footerEndDisableMargin?: true;
}

const Socials: React.FC<SocialProps> = ({
    blueSky,
    twitter,
    patreon,
    discord,
    youtube,
    paypal,
    facebook,
    buttonStyle = 'button',
}) => {
    const { theme, themeType } = useCustomTheme();

    const social = (icon: JSX.Element, link: string, name?: string) => {
        return buttonStyle === 'chip' ? (
            <Chip
                sx={{
                    paddingLeft: 0.8, // Increase left padding
                    marginRight: 1,
                }}
                variant={'outlined'}
                color="default"
                icon={icon}
                label={name}
                clickable={true}
                onClick={() => window.open(link, '_blank')}
            />
        ) : (
            <IconButton
                size="small"
                edge="start"
                sx={{
                    mr: 1,
                    ...(themeType === 'custom' && {
                        border: '1px solid',
                        backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        borderColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        '&:hover': {
                            backgroundColor: `${alpha(theme.palette.primary.light, 0.15)}`,
                        },
                    }),
                }}
                onClick={() => window.open(link, '_blank')}
            >
                {icon}
            </IconButton>
        );
    };

    return (
        <>
            {/* Conditionally render Twitter button */}
            {twitter &&
                social(
                    SocialLinksUserProfile.twitter.icon,
                    SocialLinksUserProfile.twitter.link + twitter,
                    SocialLinksUserProfile.twitter.name
                )}

            {/* Conditionally render Discord button */}
            {discord &&
                social(
                    SocialLinksUserProfile.discord.icon,
                    SocialLinksUserProfile.discord.link + discord,
                    SocialLinksUserProfile.discord.name
                )}

            {/* Conditionally render Patreon button */}
            {patreon &&
                social(
                    SocialLinksUserProfile.patreon.icon,
                    SocialLinksUserProfile.patreon.link + patreon,
                    SocialLinksUserProfile.patreon.name
                )}

            {/* Conditionally render Bluesky button */}
            {blueSky &&
                social(
                    SocialLinksUserProfile.bluesky.icon,
                    SocialLinksUserProfile.bluesky.link + blueSky,
                    SocialLinksUserProfile.bluesky.name
                )}

            {/* Conditionally render YouTube button */}
            {youtube &&
                social(
                    SocialLinksUserProfile.youtube.icon,
                    SocialLinksUserProfile.youtube.link + youtube,
                    SocialLinksUserProfile.youtube.name
                )}

            {/* Conditionally render PayPal button */}
            {paypal &&
                social(
                    SocialLinksUserProfile.paypal.icon,
                    SocialLinksUserProfile.paypal.link + paypal,
                    SocialLinksUserProfile.paypal.name
                )}

            {/* Conditionally render FaceBook button */}
            {facebook &&
                social(
                    SocialLinksUserProfile.facebook.icon,
                    SocialLinksUserProfile.facebook.link + facebook,
                    SocialLinksUserProfile.facebook.name
                )}
        </>
    );
};

export default Socials;
