import { User, UserAccountTypes } from '../workers/ApiWorker';

export const isUserSuspended = (user: User): boolean => {
    return user.userProfile.accountStatus === 'suspended';
};

export const canUserDirectlyUploadAndNotifyDiscord = (user: User): boolean => {
    return user.userProfile.canDirectUpload;
};

export const isUserVerified = (token?: string, user?: User): boolean => {
    return !!(token && user && user.verifiedAt);
};

export const isUserStaff = (user: User): boolean => {
    return (
        user.userProfile.accountType === UserAccountTypes.ADMIN ||
        user.userProfile.accountType === UserAccountTypes.MODERATOR
    );
};
