// VerifyAlert.tsx
import React, { ReactNode } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

interface VerifyAlertInputProps {
    children: ReactNode;
}

const VerifyAlert: React.FC<VerifyAlertInputProps> = ({ children }) => {
    return (
        <Box sx={{ width: '100%', marginBottom: 2 }}>
            <Alert
                severity="warning"
                variant="outlined"
                sx={{ cursor: 'default' }}
            >
                {children}
            </Alert>
        </Box>
    );
};

export default VerifyAlert;
