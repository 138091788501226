import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { BackgroundDefaultColor } from '../../../helpers/Themes';

interface PreviewCardStatusBannerProps {
    colorMain: string;
    colorDark: string;
    status: string;
    information: string;
    icon: React.JSX.Element;
}

const PreviewCardStatusBanner: React.FC<PreviewCardStatusBannerProps> = ({
    colorMain,
    colorDark,
    status,
    icon,
    information,
}) => {
    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    position: 'absolute',
                    background: `linear-gradient(to right, ${colorMain}, ${colorDark})`,
                    width: '100%',
                    top: 185,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    textAlign: 'left',
                    padding: '4px 8px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {React.cloneElement(icon, {
                    sx: {
                        marginRight: 0.5,
                        fontSize: 16,
                        color: BackgroundDefaultColor,
                    },
                })}
                <Typography
                    variant="caption"
                    color={BackgroundDefaultColor}
                    sx={{ marginRight: 0.5 }}
                >
                    {status}
                </Typography>
                <Typography
                    variant="caption"
                    color={BackgroundDefaultColor}
                    sx={{ marginRight: 0.5 }}
                >
                    |
                </Typography>
                <Typography variant="caption" color={BackgroundDefaultColor}>
                    {information}
                </Typography>
            </Box>
        </Box>
    );
};

export default PreviewCardStatusBanner;
