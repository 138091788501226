// SuspendedPage.tsx
import React, { useEffect } from 'react';
import { fadeTimeout } from '../helpers/Themes';
import { Fade, Box, Typography, Container, Button } from '@mui/material';
import { Block } from '@mui/icons-material';
import { useCustomTheme } from '../contexts/ThemeContext';
interface SuspendedPageProps {
    isMobile: boolean;
    padding?: number;
}

const SuspendedPage: React.FC<SuspendedPageProps> = ({
    isMobile,
    padding = 2,
}) => {
    const { theme } = useCustomTheme();

    useEffect(() => {}, []);

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Container
                maxWidth="sm"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '75vh',
                    textAlign: 'center',
                    padding: isMobile ? padding : padding * 2,
                }}
            >
                <Box
                    sx={{
                        mb: 2,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Block
                        sx={{
                            fontSize: 60,
                            color: theme.palette.error.main,
                        }}
                    />
                </Box>
                <Typography variant="h4" gutterBottom>
                    Account Suspended
                </Typography>
                <Typography variant="body1" color="text.secondary" paragraph>
                    Your account has been temporarily suspended. If you believe
                    this is an error or would like more information, please
                    contact our support team at discovery.onl.contact@gmail.com
                    or by creating a support ticket on the Discovery Discord
                    below:
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                        (window.location.href =
                            'https://discord.com/channels/988917780214591568/1107037645311316140')
                    }
                >
                    Contact Support
                </Button>
            </Container>
        </Fade>
    );
};

export default SuspendedPage;
