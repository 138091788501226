// src/components/modal/PolicyModal.js

import React from 'react';
import { Link, Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import CustomModal from '../universal/modals/CustomModal';

interface PolicyModalProps {
    closeModal: (resetForm?: () => void) => void; // Updated to match the definition
    isModalOpen: boolean;
    isMobile: boolean;
}

const PolicyModal: React.FC<PolicyModalProps> = ({
    isMobile,
    closeModal,
    isModalOpen,
}) => {
    const { theme } = useCustomTheme();
    return (
        <CustomModal
            isOpen={isModalOpen}
            onClose={() => closeModal()}
            isMobile={isMobile}
            cancelLabel={'Close'}
        >
            <Typography
                variant="h3"
                sx={{
                    marginBottom: 3,
                }}
                color={theme.palette.text.secondary}
            >
                PRIVACY POLICY
            </Typography>
            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                Effective Date: 25 Feb 2025
            </Typography>

            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                Welcome to Discovery ("we," "our," or "us"). This Privacy Policy
                explains how we collect, use, disclose, and safeguard your
                information when you visit our website{' '}
                <Link
                    href="https://discovery.onl"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        cursor: 'pointer',
                        color: theme.palette.warning.main,
                        textDecoration: 'none', // Ensure underline is shown
                    }}
                >
                    https://discovery.onl
                </Link>{' '}
                (the "Site"). By using our Site, you agree to the terms of this
                Privacy Policy. If you do not agree, please do not use our Site.
            </Typography>

            {/* 1. Information We Collect */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                1. Information We Collect
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (a) Account Registration Data
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                When you create an account, we collect:
                <ul>
                    <li>Username</li>
                    <li>Email address</li>
                    <li>IP address</li>
                    <li>Password (stored securely using encryption)</li>
                </ul>
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (b) Upload Data
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                When you submit content (e.g., mods), we collect:
                <ul>
                    <li>
                        Any information provided in the upload form, such as mod
                        descriptions, images, and other metadata.
                    </li>
                    <li>
                        We do not store uploaded files. Users must self-host
                        their files and provide a link. We only store
                        information about the upload and direct users to where
                        they can download the content.
                    </li>
                </ul>
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (c) User Activity Data
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                To improve user experience and provide essential features, we
                track:
                <ul>
                    <li>Uploads you view, like, subscribe to, and download.</li>
                    <li>
                        Reports submitted by users regarding content violations.
                    </li>
                </ul>
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (d) Automatically Collected Information
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We may also collect:
                <ul>
                    <li>IP address (for security and moderation purposes)</li>
                    <li>
                        Usage data (e.g., pages visited, time spent on the site)
                    </li>
                </ul>
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (e) Cookies & Local Storage
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We use cookies and similar technologies to improve site
                performance and user experience. These store data locally on
                your device to reduce database interactions. Specifically, we
                may store:
                <ul>
                    <li>Preferred upload types</li>
                    <li>Selected games</li>
                    <li>Verification status (if the user is verified)</li>
                </ul>
                These cookies are essential for the functionality of the site
                and do not track users across different websites.
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (f) Email Communications
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We may send emails for essential account-related purposes,
                including:
                <ul>
                    <li>
                        Account verification: To confirm your email address and
                        activate your account.
                    </li>
                    <li>Password recovery: If you request a password reset.</li>
                </ul>
                We do not send promotional or marketing emails.
            </Typography>

            <Typography
                variant="body2"
                color={theme.palette.text.secondary}
                sx={{ mt: 2 }}
            >
                (g) Advertisements
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We use Google AdSense to display advertisements on Discovery to
                help fund the site. Google AdSense may collect user data through
                cookies and other tracking technologies to provide personalized
                ads. These ads may be based on your browsing history, location,
                and other factors. Users can manage ad preferences through
                Google's Ad Settings or opt out of personalized ads by visiting{' '}
                <Link
                    href="https://adssettings.google.com"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        cursor: 'pointer',
                        color: theme.palette.warning.main,
                        textDecoration: 'none', // Ensure underline is shown
                    }}
                >
                    https://adssettings.google.com
                </Link>
                .
            </Typography>

            {/* 3. How We Use Your Information */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                3. How We Use Your Information
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We use your information to:
                <ul>
                    <li>Provide and maintain the Site and its features.</li>
                    <li>
                        Monitor user activity to prevent abuse and enforce our
                        Terms of Service.
                    </li>
                    <li>
                        Improve user experience and troubleshoot technical
                        issues.
                    </li>
                    <li>
                        Communicate with users regarding account-related
                        matters.
                    </li>
                    <li>
                        Display relevant advertisements through Google AdSense
                        to help fund the site.
                    </li>
                </ul>
            </Typography>

            {/* 4. How We Share Your Information */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                4. How We Share Your Information
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We do not sell or rent your personal data. However, we may share
                data:
                <ul>
                    <li>
                        With service providers (e.g., hosting services,
                        analytics tools) to operate the Site.
                    </li>
                    <li>
                        If required by law (e.g., legal requests, regulatory
                        obligations).
                    </li>
                    <li>
                        To protect our platform (e.g., enforcing our Terms of
                        Service, preventing fraudulent activity).
                    </li>
                    <li>
                        In case of a business transfer (e.g., merger,
                        acquisition, or sale of assets).
                    </li>
                    <li>
                        With Google AdSense to display personalized
                        advertisements.
                    </li>
                </ul>
            </Typography>

            {/* 5. Third-Party Links */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                5. Third-Party Links
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                Our Site may contain links to third-party websites. We are not
                responsible for their privacy practices.
            </Typography>

            {/* 6. Data Security */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                6. Data Security
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We take security seriously and use encryption and other measures
                to protect your data. However, no system is completely secure,
                so users should take precautions when sharing information
                online.
            </Typography>

            {/* 7. Your Rights & Choices */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                7. Your Rights & Choices
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                You may:
                <ul>
                    <li>Adjust browser settings to manage cookies.</li>
                    <li>
                        Contact us to update or delete your account information.
                    </li>
                    <li>
                        Manage ad preferences through Google Ad Settings at{' '}
                        <Link
                            href="https://adssettings.google.com"
                            target="_blank"
                            rel="noopener"
                            sx={{
                                cursor: 'pointer',
                                color: theme.palette.warning.main,
                                textDecoration: 'none', // Ensure underline is shown
                            }}
                        >
                            https://adssettings.google.com
                        </Link>
                    </li>
                </ul>
            </Typography>

            {/* 8. Changes to This Policy */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                8. Changes to This Policy
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                We may update this Privacy Policy from time to time. The latest
                version will always be available in the footer at{' '}
                <Link
                    href="https://discovery.onl"
                    target="_blank"
                    rel="noopener"
                    sx={{
                        cursor: 'pointer',
                        color: theme.palette.warning.main,
                        textDecoration: 'none', // Ensure underline is shown
                    }}
                >
                    https://discovery.onl
                </Link>
                .
            </Typography>

            {/* 9. Contact Us */}
            <Typography
                variant="h5"
                color={theme.palette.text.secondary}
                sx={{ mt: 3 }}
            >
                9. Contact Us
            </Typography>
            <Typography
                variant="body1"
                color={theme.palette.text.secondary}
                sx={{ mb: 2 }}
            >
                If you have any questions or requests regarding this Privacy
                Policy, contact us at: discovery.onl.contact@gmail.com.
            </Typography>
        </CustomModal>
    );
};

export default PolicyModal;
