import React, { ChangeEvent } from 'react';
import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface RadioInputProps {
    id: string;
    label: string;
    handleChange: (field: string, value: any) => void;
    hasSubmitted: boolean;
    items: {
        id: string;
        value: string;
        name: string;
        caption?: string;
    }[];
    value?: 'public' | 'private' | 'both';
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string;
    errors?: string;
    touched?: boolean;
}

const RadioInput: React.FC<RadioInputProps> = ({
    id,
    label,
    items,
    handleChange,
    hasSubmitted,
    value,
    enableLabel,
    isMobile,
    caption,
    errors,
    touched,
}) => {
    const { theme } = useCustomTheme();

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        handleChange(id, newValue);
    };

    const selectedItem = items.find((item) => item.value === value);

    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'flex-start'}
                spacing={2}
            >
                {/* Label Area */}
                {!enableLabel && (
                    <LabelInput
                        label={label}
                        enableLabel={enableLabel}
                        isMobile={isMobile}
                        caption={caption}
                    />
                )}

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                    lg={enableLabel ? 12 : 9.8}
                    xl={enableLabel ? 12 : 10.25}
                    style={{
                        display: 'flex',
                        flexDirection: 'column', // Stack content vertically in the grid
                        alignItems: 'flex-start', // Align to the left
                    }}
                >
                    <RadioGroup
                        row
                        aria-labelledby={id + '-radio-buttons-group-label'}
                        name={id + '-radio-buttons-group'}
                        value={value ?? ''}
                        onChange={handleInputChange}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row', // Ensure radio buttons are side by side
                        }}
                    >
                        {items.map((item) => (
                            <FormControlLabel
                                key={item.value}
                                value={item.value}
                                control={<Radio />}
                                label={
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            color: theme.palette.text.primary,
                                        }}
                                    >
                                        {item.name}
                                    </Typography>
                                }
                                disabled={hasSubmitted}
                            />
                        ))}
                    </RadioGroup>

                    {/* Display errors below the radio buttons */}
                    {touched && errors && (
                        <FormHelperText
                            sx={{
                                color: theme.palette.error.main,
                                marginTop: 1, // Add space between radio buttons and errors
                            }}
                        >
                            {errors}
                        </FormHelperText>
                    )}

                    {/* Display the caption of the selected item below the radio buttons */}
                    {selectedItem?.caption && (
                        <Typography
                            variant="body2"
                            sx={{
                                color: theme.palette.warning.main,
                            }}
                        >
                            {selectedItem.caption}
                        </Typography>
                    )}

                    {/* Display caption input */}
                    {enableLabel && caption && (
                        <CaptionInput caption={caption} />
                    )}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default RadioInput;
