// src/components/modal/UpdateUserModal.js

import React from 'react';
import { Typography } from '@mui/material';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { UserProfile } from '../../workers/ApiWorker';
import CustomModal from '../universal/modals/CustomModal';
import ErrorAlert from '../universal/alerts/ErrorAlert';
import TextInput from '../universal/inputs/TextInput';
import { Formik } from 'formik';
import * as Yup from 'yup';
import SuccessAlert from '../universal/alerts/SuccessAlert';
import { SocialLinksUserProfile } from '../../helpers/Socials';
import { useApi } from '../../contexts/ApiContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import {
    USER_PROFILE_UPDATED_FAILED,
    USER_PROFILE_UPDATED_SUCCESS,
} from '../../helpers/Messages';

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

interface UpdateUserModalProps {
    isMobile: boolean;
    isModalOpen: boolean;
    setError: (error: string | undefined) => void; // Updated to match the state setter type
    setSuccess: (success: string | undefined) => void; // Updated to match the state setter type
    loadedUser: UserProfile;
    error?: string;
    success?: string;
    setLoadedUser: (loadedUser: UserProfile) => void;
    closeModal: (resetForm?: () => void) => void;
}

// Define validation schema using Yup
const validationSchemaUpdateUser = Yup.object({
    description: Yup.string()
        .max(200, 'Description must be at most 200 characters.')
        .test(
            'max-line-breaks',
            'Description can only have 4 separate lines.',
            (value) => {
                if (!value) return true; // Allow empty descriptions
                const lineBreakCount = (value.match(/\n/g) || []).length; // Count the number of \n
                return lineBreakCount <= 3; // Enforce a maximum of 4 line breaks
            }
        ),
    youtube: Yup.string(),
    paypal: Yup.string(),
    patreon: Yup.string(),
    twitter: Yup.string(),
});

const UpdateUserModal: React.FC<UpdateUserModalProps> = ({
    isMobile,
    isModalOpen,
    setError,
    setSuccess,
    error,
    success,
    loadedUser,
    setLoadedUser,
    closeModal,
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { theme } = useCustomTheme();
    const { token, logout } = useApi();

    return (
        <Formik
            initialValues={{
                description: loadedUser.description,
                youtube: loadedUser.youtube,
                patreon: loadedUser.patreon,
                twitter: loadedUser.twitter,
                paypal: loadedUser.paypal,
            }}
            validationSchema={validationSchemaUpdateUser}
            enableReinitialize
            onSubmit={async (values, { resetForm }) => {
                setSuccess('');
                setError('');
                if (token) {
                    try {
                        const userUpdate = await apiWorker.updateUserProfile(
                            {
                                description: values.description,
                                twitter: values.twitter,
                                paypal: values.paypal,
                                patreon: values.patreon,
                                youtube: values.youtube,
                            },
                            token
                        );

                        setSuccess(USER_PROFILE_UPDATED_SUCCESS);
                        setLoadedUser(userUpdate.data);
                    } catch (error) {
                        setError(USER_PROFILE_UPDATED_FAILED);
                    }
                } else {
                    logout('/login');
                }
            }}
        >
            {({
                errors,
                touched,
                values,
                handleChange,
                isSubmitting,
                handleBlur,
                isValid,
                handleSubmit,
                resetForm,
            }) => (
                <CustomModal
                    isOpen={isModalOpen}
                    onClose={() => closeModal(resetForm)}
                    onConfirm={handleSubmit}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    isMobile={isMobile}
                >
                    {/* Modal content passed directly as children */}
                    <Typography
                        variant="h3"
                        sx={{
                            marginBottom: 3,
                        }}
                        color={theme.palette.text.secondary}
                    >
                        EDIT PROFILE
                    </Typography>
                    {success && <SuccessAlert message={success}></SuccessAlert>}
                    {error && <ErrorAlert message={error}></ErrorAlert>}
                    <TextInput
                        id={'description'}
                        label={'PROFILE INTRODUCTION'}
                        value={values.description}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.description}
                        errors={errors.description}
                        isMobile={isMobile}
                        multiline={true}
                    />
                    <TextInput
                        id={'twitter'}
                        label={'x'}
                        iconLabel={SocialLinksUserProfile.twitter.icon}
                        value={values.twitter}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.twitter}
                        errors={errors.twitter}
                        isMobile={isMobile}
                        startAdornment={SocialLinksUserProfile.twitter.link}
                    />
                    <TextInput
                        id={'youtube'}
                        label={'YouTube'}
                        iconLabel={SocialLinksUserProfile.youtube.icon}
                        value={values.youtube}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.youtube}
                        errors={errors.youtube}
                        isMobile={isMobile}
                        startAdornment={SocialLinksUserProfile.youtube.link}
                    />
                    <TextInput
                        id={'patreon'}
                        label={'Patreon'}
                        iconLabel={SocialLinksUserProfile.patreon.icon}
                        value={values.patreon}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.patreon}
                        errors={errors.patreon}
                        isMobile={isMobile}
                        startAdornment={SocialLinksUserProfile.patreon.link}
                    />
                    <TextInput
                        id={'paypal'}
                        label={'PayPal'}
                        iconLabel={SocialLinksUserProfile.paypal.icon}
                        value={values.paypal}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        hasSubmitted={isSubmitting}
                        touched={touched.paypal}
                        errors={errors.paypal}
                        isMobile={isMobile}
                        startAdornment={SocialLinksUserProfile.paypal.link}
                    />
                </CustomModal>
            )}
        </Formik>
    );
};

export default UpdateUserModal;
