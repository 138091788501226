import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import SearchBarInput from '../../universal/inputs/SearchBarInput';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import logo from '../../../images/discovery-logo.png';
import Socials from '../../../helpers/Socials';
import { alpha } from '@mui/system';
import Box from '@mui/material/Box';
import UserActions from '../../user/UserActions';
import { ToolBarColor } from '../../../helpers/Themes';
import { User, UserAccountTypes } from '../../../workers/ApiWorker';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../contexts/ApiContext';
import TopAppBarItemButtonLink from './TopAppBarItemButtonLink';
import {
    HomeLink,
    GamesLink,
    CreateUploadsLink,
    AdminLink,
} from '../../../helpers/Links';
import UserLogin from '../../user/UserLogin';
import NotificationActions from '../../universal/notifications/NotificationActions';
import { isUserVerified } from '../../../helpers/Users';

interface TopAppBarProps {
    isMobile: boolean;
    handleDrawerOpen: () => void;
    handleDrawerClose: () => void;
    drawerOpen: boolean;
    over: boolean;
    user?: User;
}

const TopAppBar: React.FC<TopAppBarProps> = ({ isMobile, over, user }) => {
    const { token } = useApi();
    const { themeType } = useCustomTheme();
    const navigate = useNavigate();
    const homeLink = HomeLink(isMobile);
    const gameLink = GamesLink(isMobile);
    const createUploadsLink = CreateUploadsLink(isMobile);
    const adminLink = AdminLink(isMobile);

    const handleNotificationCount = (value: number) => {
        setUsersNotifications(value);
    };

    const [usersNotifications, setUsersNotifications] = useState<number>(
        user && user.userProfile && user.userProfile.notifications
            ? user.userProfile.notifications
            : 0
    );

    useEffect(() => {
        if (user) {
            setUsersNotifications(
                user.userProfile.notifications
                    ? user.userProfile.notifications
                    : 0
            );
        }
    }, [user]);

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: ToolBarColor,
                ...(over && {
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }),
                ...(themeType === 'custom' && {
                    boxShadow: 'none', // Remove shadow
                    backgroundImage: 'none', // Remove gradient or light effect
                }),
                borderBottom: (theme) =>
                    `1px solid ${alpha(theme.palette.primary.light, 1)}`,
                boxShadow: 'none',
            }}
        >
            <Toolbar
                sx={{
                    paddingLeft: 0, // Removes left padding
                    paddingRight: 0, // Removes right padding
                    '@media (min-width: 600px)': {
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                }}
            >
                <img
                    src={logo}
                    alt="My Logo"
                    style={{
                        height: '30px', // Set the desired height
                        filter: 'hue-rotate(180deg) saturate(0%) brightness(0) invert(1)',
                        marginRight: 15,
                        marginLeft: 15,
                        cursor: 'pointer', // Change cursor on hover
                    }}
                    onClick={() => navigate('/')}
                />
                {!isMobile ? (
                    <>
                        <Box sx={{ paddingLeft: !isMobile ? 2 : undefined }} />
                        <Socials
                            twitter="dot_discovery"
                            patreon="dotdiscovery"
                            discord="dotdiscovery"
                            //    blueSky="dotdiscovery.bsky.social"
                        />
                        <TopAppBarItemButtonLink
                            icon={homeLink.icon}
                            text={homeLink.name}
                            link={homeLink.path}
                            borderLeft={true}
                            borderRight={true}
                        />
                        <TopAppBarItemButtonLink
                            icon={gameLink.icon}
                            text={gameLink.name}
                            link={gameLink.path}
                            borderLeft={false}
                            borderRight={true}
                        />
                    </>
                ) : null}
                <Box sx={{ paddingLeft: !isMobile ? 2 : undefined }} />
                <SearchBarInput isMobile={isMobile} />
                <Box sx={{ paddingLeft: !isMobile ? 2 : undefined }} />
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                    }}
                >
                    {!isMobile ? (
                        //
                        token && user ? (
                            <>
                                {isUserVerified(token, user) ? (
                                    <>
                                        <TopAppBarItemButtonLink
                                            icon={createUploadsLink.icon}
                                            text={createUploadsLink.name}
                                            link={createUploadsLink.path}
                                            borderLeft={true}
                                            borderRight={true}
                                        />
                                        <NotificationActions
                                            notificationCount={
                                                usersNotifications
                                            }
                                            handleNotificationCount={
                                                handleNotificationCount
                                            }
                                            isMobile={isMobile}
                                            borderRight={true}
                                        />
                                    </>
                                ) : (
                                    <NotificationActions
                                        notificationCount={usersNotifications}
                                        handleNotificationCount={
                                            handleNotificationCount
                                        }
                                        isMobile={isMobile}
                                        borderRight={true}
                                        borderLeft={true}
                                    />
                                )}
                                <Box sx={{ paddingLeft: 2 }} />
                                <UserActions isMobile={isMobile} user={user} />
                                <Box sx={{ paddingLeft: 2 }} />
                                {user.userProfile.accountType !==
                                    UserAccountTypes.MEMBER && (
                                    <TopAppBarItemButtonLink
                                        icon={adminLink.icon}
                                        link={adminLink.path}
                                        borderLeft={true}
                                    />
                                )}
                            </>
                        ) : (
                            <UserLogin isMobile={isMobile} borderLeft={true} />
                        )
                    ) : token && user ? (
                        <>
                            <NotificationActions
                                notificationCount={usersNotifications}
                                handleNotificationCount={
                                    handleNotificationCount
                                }
                                isMobile={isMobile}
                            />
                            {user.userProfile.accountType !==
                                UserAccountTypes.MEMBER && (
                                <TopAppBarItemButtonLink
                                    icon={adminLink.icon}
                                    link={adminLink.path}
                                />
                            )}
                        </>
                    ) : (
                        <Box sx={{ paddingLeft: 2 }} />
                    )}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default TopAppBar;
