import React, { useEffect, useState } from 'react';
import { useApi } from '../../contexts/ApiContext';
import Box from '@mui/material/Box';
import { User, UserProfile } from '../../workers/ApiWorker';
import EditIcon from '@mui/icons-material/Edit';
import { acceptedTypesArray, acceptedTypesString } from '../../helpers/Images';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import CircularProgress from '@mui/material/CircularProgress';

interface UserProfileImageProps {
    loadedUser: UserProfile;
    user?: User;
    loadedUserImage?: string | null;
    uploadImage?: File | undefined;
    setUploadImage?: (image: File | undefined) => void;
    imageSize?: 'medium' | 'large';
}

const createApiWorker = createWorkerFactory(
    () => import('../../workers/ApiWorker')
);

const UserProfileImage: React.FC<UserProfileImageProps> = ({
    user,
    loadedUser,
    loadedUserImage,
    uploadImage,
    setUploadImage,
    imageSize = 'large',
}) => {
    const apiWorker = useWorker(createApiWorker);
    const { theme } = useCustomTheme();
    const { token } = useApi();

    const [isUploadingNewImage, setIsUploadingNewImage] =
        useState<boolean>(false);

    useEffect(() => {
        const postUploadImage = async () => {
            if (uploadImage && token) {
                setIsUploadingNewImage(true);
                if (loadedUserImage) {
                    URL.revokeObjectURL(loadedUserImage);
                }

                try {
                    const createImage = await apiWorker.postImage(
                        {
                            uuid: loadedUser.uuid,
                            type: 'main',
                            scope: 'users',
                            image: uploadImage,
                        },
                        token
                    );

                    await apiWorker.updateUserProfile(
                        {
                            imageMain: createImage.data.key,
                        },
                        token
                    );

                    window.location.reload();
                } catch (error) {
                    console.warn('error trying to upload image');
                    setIsUploadingNewImage(false);
                }
            }
        };

        if (uploadImage && token) {
            postUploadImage()
                .then(() => {})
                .catch((error) => {
                    console.log('Error uploading image:', error);
                });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadImage]);

    // Trigger file input on card click
    const handleCardClick = () => {
        if (setUploadImage) {
            document.getElementById('imageUploadInput')?.click();
        }
    };

    // Function to handle file selection
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]; // Only allow one image
        if (file && setUploadImage) {
            if (!acceptedTypesArray.includes(file.type)) {
                event.target.value = ''; // Reset the file input
                return;
            }
            // Clean up any previous object URL before creating a new one
            setUploadImage(file); // Store file temporarily in state
        }

        // Reset the file input so the same image can be uploaded again
        event.target.value = '';
    };

    return (
        <Box
            onClick={handleCardClick}
            sx={{
                position: 'relative', // Allows absolutely positioned children to be placed within the Box
                width: imageSize === 'large' ? '212px' : '146px',
                height: imageSize === 'large' ? '212px' : '146px',
                cursor:
                    user &&
                    user.uuid === loadedUser.uuid &&
                    !isUploadingNewImage
                        ? 'pointer'
                        : 'default', // Fixing the ternary syntax
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
            }}
        >
            {/* Render the pencil icon if conditions are met */}
            {user &&
                user.userProfile.uuid === loadedUser.uuid &&
                !isUploadingNewImage && (
                    <Box
                        sx={{
                            position: 'absolute', // Position the icon relative to the Box
                            top: 8, // Slight padding from the top
                            right: 8, // Slight padding from the left
                            zIndex: 1, // Ensure it stays on top of other content
                        }}
                    >
                        <EditIcon />
                    </Box>
                )}

            {isUploadingNewImage ? (
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)', // Center the box
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : null}

            {loadedUserImage ? (
                <img
                    src={loadedUserImage}
                    alt="User Profile"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <h1
                    style={{
                        margin: 0,
                        backgroundColor: theme.palette.text.disabled,
                        color: theme.palette.text.primary,
                        fontSize: '4rem',
                        lineHeight: imageSize === 'large' ? '212px' : '146px',
                    }}
                >
                    {loadedUser.username.slice(0, 1).toUpperCase()}
                </h1>
            )}

            {user &&
                user.userProfile.uuid === loadedUser.uuid &&
                setUploadImage &&
                !isUploadingNewImage && (
                    <input
                        id="imageUploadInput"
                        type="file"
                        accept={acceptedTypesString}
                        style={{
                            display: 'none',
                        }}
                        onChange={handleFileChange}
                    />
                )}
        </Box>
    );
};

export default UserProfileImage;
