import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Link, Fade } from '@mui/material';
import logo from '../images/discovery-logo.png';
import { useApi } from '../contexts/ApiContext';
import { useNavigate } from 'react-router-dom';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import axios from 'axios';
import { unknownError } from '../helpers/ApiResponses';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';
import { useCustomTheme } from '../contexts/ThemeContext';
import ReCAPTCHA from 'react-google-recaptcha';

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Enter a valid email')
        .required('Email is required'),
    password: Yup.string()
        .min(10, 'Password should be at least 10 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Password must contain at least 1 uppercase, 1 lowercase, and 1 number'
        )
        .required('Password is required'),
});

const LoginPage: React.FC = () => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { theme } = useCustomTheme();
    const navigate = useNavigate();
    const { storeTokenAndUser } = useApi();
    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);

    const [captchaValue, setCaptchaValue] = useState<string | null>(null);

    const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px',
                        minHeight: '400px', // Ensures consistent height
                    }}
                >
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: '80px', marginBottom: '2rem' }}
                    />
                    <Typography
                        variant="h2"
                        sx={{
                            fontWeight: 700,
                            marginBottom: '0rem',
                            cursor: 'default',
                        }}
                    >
                        LOGIN
                    </Typography>
                    <Typography
                        variant="body2"
                        sx={{
                            marginBottom: '2rem',
                            color: theme.palette.text.disabled,
                            cursor: 'default',
                        }}
                    >
                        Please enter your email and password
                    </Typography>
                    {showError && <ErrorAlert message={error} />}

                    <Formik
                        initialValues={{ email: '', password: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values) => {
                            setShowError(false);
                            if (captchaValue !== null && recaptchaRef.current) {
                                try {
                                    const response = await apiWorker.login(
                                        values.email,
                                        values.password,
                                        captchaValue
                                    );
                                    storeTokenAndUser(response.accessToken);
                                    navigate('/');
                                } catch (error) {
                                    setError(
                                        axios.isAxiosError(error) &&
                                            error.response?.data
                                            ? error.response.data.message
                                            : unknownError().message
                                    );
                                    setShowError(true);
                                    setCaptchaValue(null);
                                    recaptchaRef.current.reset();
                                }
                            }
                        }}
                    >
                        {({
                            errors,
                            touched,
                            values,
                            handleChange,
                            isSubmitting,
                            handleBlur,
                            isValid,
                        }) => (
                            <Form style={{ width: '100%' }}>
                                <TextInput
                                    id="email"
                                    label="Email"
                                    value={values.email}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.email}
                                    errors={errors.email}
                                    enableLabel={true}
                                />
                                <TextInput
                                    id="password"
                                    label="Password"
                                    value={values.password}
                                    handleChange={handleChange}
                                    handleBlur={handleBlur}
                                    hasSubmitted={isSubmitting}
                                    touched={touched.password}
                                    errors={errors.password}
                                    type="password"
                                    enableLabel={true}
                                />
                                <Box
                                    sx={{
                                        textAlign: 'right',
                                        alignSelf: 'flex-end',
                                        marginBottom: '1rem',
                                    }}
                                >
                                    <Link
                                        href="/forgot-password"
                                        underline="hover"
                                        variant="body2"
                                        sx={{
                                            color: theme.palette.warning.main,
                                        }}
                                    >
                                        <i>Forgot your password?</i>
                                    </Link>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center', // Centers horizontally
                                        alignItems: 'center', // Centers vertically
                                        marginBottom: 2,
                                    }}
                                >
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={
                                            process.env
                                                .REACT_APP_RECAPTCHA_SITE_KEY as string
                                        }
                                        onChange={handleCaptchaChange}
                                    />
                                </Box>

                                <Button
                                    fullWidth
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        captchaValue === null ||
                                        !isValid
                                    }
                                    variant="contained"
                                    sx={{
                                        padding: '0.8rem',
                                        marginBottom: 1.8,
                                    }}
                                >
                                    LOGIN
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Typography variant="body2">
                        <Link
                            href="/register"
                            underline="hover"
                            variant="body2"
                            sx={{ color: theme.palette.warning.main }}
                        >
                            <i>Not a member yet? </i>{' '}
                            <i>
                                <b>Register!</b>
                            </i>
                        </Link>
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
};

export default LoginPage;
