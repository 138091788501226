// Messages.ts

export const GENERIC_SUCCESS = 'Success!';
export const GENERIC_ERROR = 'Error!';

export const UPLOAD_FILE_FAILED = 'Sorry, could not upload the selected file.';
export const UPLOAD_IMAGE_FAILED =
    'Sorry, could not upload the selected image.';

export const UPDATE_UPLOAD_GET_IMAGE_FAILED =
    'Sorry, an error occurred attempting to get your image to upload.';

export const CREATE_UPLOAD_VERSION_FAILED =
    'Sorry, an error occurred while attempting to add a new version.';

export const UPDATE_UPLOAD_FAILED =
    'Sorry, an error occurred while attempting to update.';

export const CREATE_UPLOAD_GET_IMAGE_FAILED =
    'Sorry, an error occurred attempting to get your image to upload.';

export const CREATE_UPLOAD_FAILED = 'Sorry, an error occurred while uploading.';
export const UPDATE_UPLOAD_UPDATE_STATUS_FAILED =
    'Sorry, an error occurred whilst updating the status of the upload.';

export const REJECT_UPLOAD_FAILED =
    'Sorry, an error occurred while rejecting the upload.';

export const CREATE_REPORT_SUCCESS =
    'Report has been submitted. Our staff will now review your report.';
export const CREATE_REPORT_FAILED =
    'Sorry, you can only submit one report per upload.';

export const SUSPEND_USERS_FAILED =
    'Sorry, an error occurred whilst suspending users.';
export const UNSUSPEND_USERS_FAILED =
    'Sorry, an error occurred whilst unsuspending users.';

export const USER_PROFILE_UPDATED_SUCCESS = 'Profile has been updated.';
export const USER_PROFILE_UPDATED_FAILED =
    'Sorry, an error occurred whilst updating your profile.';

export const FOLLOW_USER_FAILED = 'ERROR - FOLLOWING USER';

export const SEND_TO_DISCORD_FAILED =
    'Sorry, an error occurred while sending to Discord.';
export const SEND_TO_DISCORD_SUCCESS = 'Sent to Discord!';

export const CREATE_DIRECT_UPLOAD_CORE_DATA_FAILED =
    'Sorry, could not create the information for the direct upload.';

export const FILE_OVER_500MB_FAILED = 'Selected file was over 500MB!';

export const REPORT_REMOVED_SUCCESS = 'Report has been removed.';
export const REPORT_REMOVED_FAILED = 'Sorry, could not remove the report.';

export const DELETE_UPLOAD_FAILED =
    'Sorry, an error occurred whilst trying to remove the upload.';

export const POST_REGISTER_SUCCESS =
    'Your account has been successfully created! Please head back to the login page to sign in. You will also receive an email to verify your account. Be sure to be logged in when clicking the verification link in the email!';

export const POST_RESET_PASSWORD_FAILED =
    'Sorry, your password has not been reset due to an error';
export const POST_RESET_PASSWORD_SUCCESS =
    'Success! Your password has been updated. Please return to the login page and try again';

export const POST_FORGOT_PASSWORD_FAILED =
    'Sorry, there was an error attempting to send your password reset email.';
export const POST_FORGOT_PASSWORD_SUCCESS =
    'Success! We have sent you an email which will allow you to reset your password.';

export const POST_VERIFY_ACCOUNT_FAILED =
    'Sorry, there was an error attempting to verify your account.';
export const POST_VERIFY_ACCOUNT_SUCCESS =
    'Success! Your account has been verified, please click the button below to return to the home page.';
