// CreateUploadPage.tsx
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
    Button,
    CardContent,
    Fade,
    FormControlLabel,
    LinearProgress,
    Radio,
    RadioGroup,
    Step,
    StepContent,
    StepIconProps,
    StepLabel,
    Stepper,
    Typography,
} from '@mui/material';
import { useCustomTheme } from '../contexts/ThemeContext';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../contexts/ApiContext';
import Card from '@mui/material/Card';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Info, Image, CheckCircle } from '@mui/icons-material';
import {
    CreateUploadBasicDetailsProps,
    CreateUploadFinalizeProps,
    CreateUploadProps,
    downloadTypeOptions,
    Game,
    earlyAccessReleaseAddedDays,
    Upload,
    UploadType,
    User,
    UserProfile,
    standardReleaseAddedDays,
} from '../workers/ApiWorker';
import TextInput from '../components/universal/inputs/TextInput';
import SelectInput from '../components/universal/inputs/SelectInput';
import MultiSelectInput from '../components/universal/inputs/MultiSelectInput';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import ImageUpload from '../components/universal/images/ImageUploads';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import TagInput from '../components/universal/inputs/TagInput';
import { fadeTimeout, getOverallPadding } from '../helpers/Themes';
import PageTopSection from '../components/universal/pageTopSection/PageTopSection';
import RichTextInput from '../components/universal/inputs/RichTextInput';
import CheckboxInput from '../components/universal/inputs/CheckboxInput';
import { v4 as uuidv4 } from 'uuid';
import SearchMultiSelectInput from '../components/universal/inputs/SearchMultiSelectInput';
import {
    CREATE_UPLOAD_FAILED,
    CREATE_UPLOAD_GET_IMAGE_FAILED,
    UPLOAD_IMAGE_FAILED,
} from '../helpers/Messages';
import ReuseDetailsModal from '../components/modal/ReuseDetailsModal';
import { AxiosProgressEvent } from 'axios';
import DateTimeInput from '../components/universal/inputs/DateTimeInput';
import RadioInput from '../components/universal/inputs/RadioInput';

interface CreateUploadPageProps {
    isMobile: boolean;
    padding?: number;
    user?: User;
}

interface StepProps {
    label: string;
    info: string;
    icon: JSX.Element;
    content: JSX.Element;
}

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const CreateUploadPage: React.FC<CreateUploadPageProps> = ({
    isMobile,
    padding,
    user,
}) => {
    const apiWorker = useWorker(createApiWorker);

    const [switchCheckedPublic, setSwitchCheckedPublic] =
        React.useState<boolean>(false);
    const [switchCheckedPrivate, setSwitchCheckedPrivate] =
        React.useState<boolean>(false);

    const [error, setError] = React.useState<string | undefined>(undefined);
    const [success, setSuccess] = React.useState<string | undefined>(undefined);

    const [loadingGames, setLoadingGames] = useState<boolean>(true);
    const [loadingCollaborators, setLoadingCollaborators] =
        useState<boolean>(true);
    const [games, setGames] = useState<Game[] | null>(null);
    const [collaborators, setCollaborators] = useState<UserProfile[] | null>(
        null
    );
    const [isReuseDetailsModalOpen, setIsReuseDetailsModalOpen] =
        React.useState<boolean>(false);

    const [selectedDownloadTypeValue, setSelectedDownloadTypeValue] = useState<
        string | null
    >(null);

    const { theme } = useCustomTheme();
    const { token, uploadTypes, logout } = useApi();
    const navigate = useNavigate();

    const [uploadImage, setUploadImage] = useState<File | undefined>(undefined);

    const [uuid, setUuid] = useState<string>(uuidv4());

    const [progress, setProgress] = React.useState<number>(0);

    // Define validation schema using Yup
    const validationSchemaBasicDetails = Yup.object({
        name: Yup.string()
            .required('Title is required')
            .max(25, 'Caption must be at most 25 characters'),
        uploadType: Yup.string().required('Category is required'),
        videoUrl: Yup.string().matches(
            /^(https?:\/\/)?(www\.)?(youtube\.com\/watch\?v=[\w-]+|youtu\.be\/(?!@)[\w-]+)/,
            'Must be a valid YouTube URL: youtube.com/watch?v=video_id or youtu.be/video_id)'
        ), // Ensures it starts with http/https and matches YouTube domains
        miniDescription: Yup.string()
            .required('Caption is required')
            .max(45, 'Caption must be at most 45 characters'),
        description: Yup.string().required('Upload Description is required'),
        games: Yup.array().min(1, 'At least one Game is required'),
    });

    // Define validation schema using Yup
    const validationSchemaFinalizeDetails = Yup.object({
        selectedDownloadLinkType: Yup.string().required(
            'Please select a Download Type'
        ),
        tags: Yup.array()
            .min(1, 'At least one Tag is required')
            .test(
                'alphanumeric',
                'Each tag must only contain letters (A-Z), numbers (0-9), and spaces ( )',
                (tags) => {
                    // Check if any tag contains non-alphanumeric characters
                    if (tags && tags.length > 0) {
                        return tags.every((tag) => /^[a-zA-Z0-9 ]+$/.test(tag)); // Updated regex to allow spaces
                    }
                    return true; // return true if tags are not provided or empty (min() check will handle non-empty requirement)
                }
            ),
        downloadLinkPublic: Yup.string()
            .url('Public Download Link must be a valid URL')
            .test(
                'download-link-public-required',
                'Public Download Link is required if the selected download type is Public or Early Access Patreon',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'public' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (!value) return false;
                    }
                    return true;
                }
            )
            .test(
                'download-link-public-no-patreon-links',
                'Public Download Link cannot be a Patreon link',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'public' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (value && value.toLowerCase().includes('patreon')) {
                            return false; // Fail validation if public is true and Patreon is in the link
                        }
                    }
                    return true;
                }
            ),
        downloadLinkPrivate: Yup.string()
            .url('Patreon Download Link must be a valid URL')
            .test(
                'download-link-private-required',
                'Patreon Download Link is required if the selected download type is Patreon Exclusive or Early Access Patreon',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'private' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (!value) return false;
                    }
                    return true;
                }
            )
            .test(
                'download-link-private-only-patreon-links',
                'Patreon Download Link must be a Patreon link to a post. The link must start with https://www.patreon.com/posts/',
                function (value) {
                    if (
                        this.parent.selectedDownloadLinkType === 'private' ||
                        this.parent.selectedDownloadLinkType === 'both'
                    ) {
                        if (
                            value &&
                            !/^https:\/\/www\.patreon\.com\/posts\/.+/.test(
                                value.toLowerCase()
                            )
                        ) {
                            return false; // Fail validation if public is true and Patreon is in the link
                        }
                    }
                    return true;
                }
            ),
        scheduledAtPublic: Yup.string()
            .nullable()
            .test('scheduled-at-public-both-required', '', function (value) {
                if (this.parent.selectedDownloadLinkType === 'both') {
                    if (!value) {
                        return false;
                    }
                }
                return true;
            })
            .test(
                'scheduled-at-public-both',
                'Public Release Date must set after the Patreon Exclusive Date',
                function (value) {
                    if (this.parent.selectedDownloadLinkType === 'both') {
                        const parentScheduledAtPrivate =
                            this.parent.scheduledAtPrivate;

                        if (value !== undefined && value !== null) {
                            if (parentScheduledAtPrivate) {
                                const valueDate = new Date(value);
                                const parentScheduledAtPrivateDate = new Date(
                                    parentScheduledAtPrivate
                                );

                                // Check if the value is less than or equal to parentScheduledAtPrivate
                                if (valueDate <= parentScheduledAtPrivateDate) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                }
            ),
        drm: Yup.boolean().required('DRM is required'),
        collaborators: Yup.array(),
    });

    const showReuseDetailsModal = () => {
        setIsReuseDetailsModalOpen(true);
    };

    const closeReuseDetailsModal = () => {
        setIsReuseDetailsModalOpen(false);
    };

    const uploadDataBasicDetailsDefault: CreateUploadBasicDetailsProps = {
        name: '',
        uploadType: '',
        miniDescription: '',
        description: '',
        games: [],
        videoUrl: '',
    };
    const [uploadDataBasicDetails, setUploadDataBasicDetails] =
        React.useState<CreateUploadBasicDetailsProps>(
            uploadDataBasicDetailsDefault
        );

    const uploadDataFinalizeDetailsDefault: CreateUploadFinalizeProps = {
        collaborators: [],
        downloadLinkPrivate: '',
        downloadLinkPublic: '',
        drm: false,
        tags: [],
        additionalTypeInformation: undefined,
        nsfw: false,
        scheduledAtPublic: undefined,
        scheduledAtPrivate: undefined,
        selectedDownloadLinkType: undefined,
    };
    const [uploadDataFinalizeDetails, setUploadDataFinalizeDetails] =
        React.useState<CreateUploadFinalizeProps>(
            uploadDataFinalizeDetailsDefault
        );

    const [foundGames, setFoundGames] = useState<Game[]>([]); // State to hold found Game objects
    const [foundType, setFoundType] = useState<UploadType | undefined>(
        undefined
    ); // State for found type

    const [allDataSubmitted, setAllDataSubmitted] = useState(false);

    useEffect(() => {
        // Only attempt to find games if they are loaded and uploadDataBasicDetails.games is not empty
        if (games && uploadDataBasicDetails.games.length > 0) {
            // Find all games that match the UUIDs in uploadDataBasicDetails.games
            const matchedGames = uploadDataBasicDetails.games
                .map((uuid) => games.find((game) => game.uuid === uuid))
                .filter(Boolean) as Game[]; // Filter out any undefined values

            setFoundGames(matchedGames); // Set the found games
        }
    }, [games, uploadDataBasicDetails.games]);

    useEffect(() => {
        // Check if uploadTypes is not null and then find the Type
        if (uploadTypes) {
            const type = uploadTypes.find(
                (type) => type.uuid === uploadDataBasicDetails.uploadType
            );
            setFoundType(type ? type : undefined); // Set found type name or empty string if not found
        }
    }, [uploadTypes, uploadDataBasicDetails.uploadType]); // Re-run when uploadTypes or uploadData.type changes

    useEffect(() => {
        const getGames = async () => {
            setLoadingGames(true);
            const response = await apiWorker.getGames();
            setGames(response.data);
            setLoadingGames(false);
        };
        const getCollaborators = async () => {
            setLoadingCollaborators(true);
            const response = await apiWorker.getUserProfiles({
                count: 2000,
            });
            setCollaborators(response.data);
            setLoadingCollaborators(false);
        };
        getGames().catch(console.error);
        getCollaborators().catch(console.error);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleImageUpload = async (value?: File) => {
        setUploadImage(value);
    };

    const handleReuseDetails = async (selectedUpload: Upload) => {
        closeReuseDetailsModal();

        setUploadDataBasicDetails({
            name: selectedUpload.name,
            uploadType: selectedUpload.type.uuid,
            miniDescription: selectedUpload.miniDescription,
            description: selectedUpload.description,
            games: selectedUpload.games.map((game) => game.uuid),
            videoUrl: selectedUpload.videoUrl
                ? selectedUpload.videoUrl
                : uploadDataBasicDetailsDefault.videoUrl,
        });

        setUploadDataFinalizeDetails({
            downloadLinkPrivate:
                uploadDataFinalizeDetailsDefault.downloadLinkPrivate,
            downloadLinkPublic:
                uploadDataFinalizeDetailsDefault.downloadLinkPublic,
            drm: selectedUpload.drm,
            tags: selectedUpload.tags,
            additionalTypeInformation: selectedUpload.additionalTypeInformation
                ? selectedUpload.additionalTypeInformation
                : uploadDataFinalizeDetailsDefault.additionalTypeInformation,
            nsfw: selectedUpload.nsfw,
            collaborators: selectedUpload.collaborators
                ? selectedUpload.collaborators.map(
                      (collaborator) => collaborator.uuid
                  )
                : uploadDataFinalizeDetailsDefault.collaborators,
            scheduledAtPrivate:
                uploadDataFinalizeDetailsDefault.scheduledAtPrivate,
            scheduledAtPublic:
                uploadDataFinalizeDetailsDefault.scheduledAtPublic,
            selectedDownloadLinkType:
                uploadDataFinalizeDetailsDefault.selectedDownloadLinkType,
        });
    };

    // Custom StepIcon component to create the blue circle with the white icon
    const CustomStepIcon: React.FC<
        StepIconProps & { iconElement: JSX.Element }
    > = ({ iconElement, active, completed, className }) => {
        return (
            <Box
                className={className} // This ensures default MUI styling (active/completed states)
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 34, // Circle width
                    height: 34, // Circle height
                    borderRadius: '50%', // Ensures the icon is circular
                    backgroundColor:
                        active || completed
                            ? theme.palette.primary.main
                            : theme.palette.primary.main,
                    position: 'relative',
                    marginLeft: -0.55, // Adjust margin to align with the step line
                }}
            >
                {iconElement}
            </Box>
        );
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = (values?: CreateUploadFinalizeProps) => {
        if (activeStep === 2) {
            if (values) {
                setUploadDataFinalizeDetails(values);
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleDownloadTypeChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setSelectedDownloadTypeValue(event.target.value);
    };

    const steps: StepProps[] = [
        {
            label: 'BASIC DETAILS',
            info: 'Essential information required for your submission to Discovery',
            icon: <Info />,
            content: (
                <>
                    {user && token && uploadTypes !== null && games !== null ? (
                        <>
                            <Formik
                                enableReinitialize
                                initialValues={uploadDataBasicDetails}
                                validationSchema={validationSchemaBasicDetails}
                                onSubmit={async (values, { resetForm }) => {
                                    setUploadDataBasicDetails(values);
                                    handleNext();
                                }}
                            >
                                {({
                                    errors,
                                    touched,
                                    values,
                                    handleChange,
                                    isSubmitting,
                                    handleBlur,
                                    isValid,
                                    setFieldValue,
                                }) => {
                                    return (
                                        <Form>
                                            <SelectInput
                                                items={uploadTypes?.map(
                                                    (item) => ({
                                                        id: item.uuid,
                                                        value: item.uuid,
                                                        name: item.name,
                                                    })
                                                )}
                                                id={'uploadType'}
                                                label={'CATEGORY *'}
                                                value={values.uploadType}
                                                errors={errors.uploadType}
                                                touched={touched.uploadType}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                handleChange={setFieldValue}
                                                isMobile={isMobile}
                                                sortByName={true}
                                            />
                                            <MultiSelectInput
                                                items={games?.map((item) => ({
                                                    id: item.uuid,
                                                    value: item.uuid,
                                                    name:
                                                        item.name +
                                                        ' (' +
                                                        item.system +
                                                        ')',
                                                }))}
                                                id={'games'}
                                                label={'GAME(S) *'}
                                                caption={
                                                    'Select one or more games. The first game selected will serve as the primary display.'
                                                }
                                                value={values.games}
                                                errors={errors.games}
                                                touched={touched.games}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                handleChange={setFieldValue}
                                                isMobile={isMobile}
                                                sortByName={true}
                                            />
                                            <TextInput
                                                id={'name'}
                                                label={'TITLE *'}
                                                value={values.name}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={touched.name}
                                                errors={errors.name}
                                                isMobile={isMobile}
                                            />
                                            <TextInput
                                                id={'miniDescription'}
                                                label={'CAPTION *'}
                                                value={values.miniDescription}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.miniDescription
                                                }
                                                errors={errors.miniDescription}
                                                isMobile={isMobile}
                                                caption="The descriptive text visible in the thumbnail of the upload"
                                            />
                                            <RichTextInput
                                                id={'description'}
                                                label={'UPLOAD DESCRIPTION *'}
                                                value={values.description}
                                                errors={errors.description}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touchedFormik={
                                                    touched.description
                                                }
                                                handleChange={setFieldValue}
                                                isMobile={isMobile}
                                            />
                                            <TextInput
                                                id={'videoUrl'}
                                                label={'YOUTUBE'}
                                                caption={
                                                    'Must be a link to a video. Not your YouTube account!'
                                                }
                                                value={values.videoUrl}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={touched.videoUrl}
                                                errors={errors.videoUrl}
                                                isMobile={isMobile}
                                            />
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'flex-end',
                                                    marginTop: '1rem',
                                                }}
                                            >
                                                <Button
                                                    type="submit"
                                                    disabled={
                                                        isSubmitting || !isValid
                                                    }
                                                    variant="contained"
                                                    sx={{
                                                        padding: '0.8rem',
                                                    }}
                                                >
                                                    CONTINUE
                                                </Button>
                                                <Button
                                                    type="button"
                                                    disabled={allDataSubmitted}
                                                    variant="outlined"
                                                    color="warning"
                                                    onClick={() =>
                                                        showReuseDetailsModal()
                                                    }
                                                    sx={{
                                                        padding: '0.8rem',
                                                        marginLeft: 2,
                                                    }}
                                                >
                                                    REUSE DETAILS
                                                </Button>
                                            </Box>
                                        </Form>
                                    );
                                }}
                            </Formik>
                            <ReuseDetailsModal
                                closeModal={closeReuseDetailsModal}
                                isModalOpen={isReuseDetailsModalOpen}
                                isMobile={isMobile}
                                user={user}
                                handleReuseDetails={handleReuseDetails}
                                width={'75%'}
                            />
                        </>
                    ) : null}
                </>
            ),
        },
        {
            label: 'MEDIA',
            info: 'Visual content for your submission. Accepted formats include JPEG, PNG, and GIF files',
            icon: <Image />,
            content: (
                <>
                    <ImageUpload
                        uploadData={{
                            title: uploadDataBasicDetails.name,
                            description: uploadDataBasicDetails.miniDescription,
                            type: foundType,
                            games: foundGames,
                        }}
                        file={uploadImage}
                        handleImageUpload={handleImageUpload}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginTop: '1rem',
                        }}
                    >
                        <Button
                            type="button"
                            disabled={!uploadImage}
                            variant="contained"
                            onClick={() => handleNext()}
                            sx={{
                                padding: '0.8rem',
                                marginLeft: 2,
                            }}
                        >
                            CONTINUE
                        </Button>
                        <Button
                            type="button"
                            variant="outlined"
                            color="inherit"
                            sx={{
                                padding: '0.8rem',
                                marginLeft: 2,
                            }}
                            onClick={() => handleBack()}
                        >
                            BACK
                        </Button>
                    </Box>
                </>
            ),
        },
        {
            label: 'FINALIZE',
            info: 'The concluding configurations required to finalize your submission for Discovery',
            icon: <CheckCircle />,
            content: (
                <>
                    {collaborators !== null && user ? (
                        <Formik
                            enableReinitialize
                            initialValues={uploadDataFinalizeDetails}
                            validationSchema={validationSchemaFinalizeDetails}
                            onSubmit={async (values, { resetForm }) => {
                                setProgress(0);
                                setAllDataSubmitted(true);
                                setSuccess('');
                                setError('');
                                setUploadDataFinalizeDetails(values);

                                if (token) {
                                    if (uploadImage) {
                                        let createUpload = undefined;
                                        let createImage = undefined;

                                        try {
                                            createImage =
                                                await apiWorker.postImage(
                                                    {
                                                        uuid: uuid,
                                                        type: 'main',
                                                        scope: 'uploads',
                                                        image: uploadImage,
                                                    },
                                                    token,
                                                    (
                                                        progressEvent: AxiosProgressEvent
                                                    ) => {
                                                        const percentCompleted =
                                                            Math.round(
                                                                (progressEvent.loaded *
                                                                    100) /
                                                                    (progressEvent.total ??
                                                                        progressEvent.loaded)
                                                            );
                                                        setProgress(
                                                            percentCompleted
                                                        );
                                                    }
                                                );
                                        } catch (error) {
                                            setError(UPLOAD_IMAGE_FAILED);
                                        }

                                        if (createImage) {
                                            try {
                                                const uploadData: CreateUploadProps =
                                                    {
                                                        ...values,
                                                        ...uploadDataBasicDetails,
                                                    };

                                                createUpload =
                                                    await apiWorker.postUpload(
                                                        uploadData,
                                                        token,
                                                        uuid,
                                                        createImage.data.key,
                                                        foundType
                                                    );

                                                navigate(
                                                    `/${createUpload.data.author.username}/${uuid}`
                                                );
                                            } catch (error) {
                                                setError(CREATE_UPLOAD_FAILED);
                                            }
                                        }
                                    } else {
                                        setError(
                                            CREATE_UPLOAD_GET_IMAGE_FAILED
                                        );
                                    }

                                    setAllDataSubmitted(false);
                                    setProgress(0);
                                } else {
                                    logout('/login');
                                }
                            }}
                        >
                            {({
                                errors,
                                touched,
                                values,
                                handleChange,
                                isSubmitting,
                                handleBlur,
                                isValid,
                                setFieldValue,
                            }) => (
                                <Form>
                                    {/*<CheckboxInput*/}
                                    {/*    id={'nsfw'}*/}
                                    {/*    label={'NSFW'}*/}
                                    {/*    valueOn={true}*/}
                                    {/*    valueOff={false}*/}
                                    {/*    enableLabel={false}*/}
                                    {/*    value={values.nsfw}*/}
                                    {/*    handleChange={setFieldValue}*/}
                                    {/*    hasSubmitted={allDataSubmitted}*/}
                                    {/*    isMobile={isMobile}*/}
                                    {/*/>*/}
                                    {foundType?.name === 'character' ||
                                    foundType?.name === 'character data' ? (
                                        <CheckboxInput
                                            id={'additionalTypeInformation'}
                                            label={'WOMEN'}
                                            valueOn={'women'}
                                            valueOff={undefined}
                                            enableLabel={false}
                                            value={
                                                values.additionalTypeInformation
                                            }
                                            handleChange={setFieldValue}
                                            hasSubmitted={allDataSubmitted}
                                            isMobile={isMobile}
                                        />
                                    ) : null}
                                    <SearchMultiSelectInput
                                        items={collaborators
                                            ?.filter(
                                                (item) =>
                                                    item.uuid !==
                                                    user.userProfile.uuid
                                            ) // Filter out your user
                                            .map((item) => ({
                                                id: item.uuid,
                                                value: item.uuid,
                                                name: item.username,
                                            }))}
                                        id={'collaborators'}
                                        label={'COLLABORATOR(S)'}
                                        caption={
                                            'Choose users who have worked with you to create this mod.'
                                        }
                                        value={values.collaborators}
                                        errors={errors.collaborators}
                                        touched={touched.collaborators}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        handleChange={setFieldValue}
                                        isMobile={isMobile}
                                        sortByName={true}
                                    />
                                    <TagInput
                                        id={'tags'}
                                        label={'TAGS *'}
                                        value={values.tags}
                                        handleChange={setFieldValue}
                                        handleBlur={handleBlur}
                                        hasSubmitted={allDataSubmitted}
                                        touched={touched.tags}
                                        errors={errors.tags}
                                        isMobile={isMobile}
                                        caption="Allowed: A-Z, 0-9, and spaces. Press Enter to add a tag."
                                    />

                                    <RadioInput
                                        id={'selectedDownloadLinkType'}
                                        label={'DOWNLOAD TYPE *'}
                                        items={downloadTypeOptions.map(
                                            (item) => ({
                                                id: item.value,
                                                value: item.value,
                                                name: item.label,
                                                caption: item.caption,
                                            })
                                        )}
                                        value={values.selectedDownloadLinkType}
                                        handleChange={setFieldValue}
                                        hasSubmitted={allDataSubmitted}
                                        errors={errors.selectedDownloadLinkType}
                                        touched={
                                            touched.selectedDownloadLinkType
                                        }
                                    />

                                    {values.selectedDownloadLinkType ===
                                    'private' ? (
                                        <>
                                            <TextInput
                                                id={'downloadLinkPrivate'}
                                                label={
                                                    'PATREON DOWNLOAD LINK *'
                                                }
                                                value={
                                                    values.downloadLinkPrivate
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.downloadLinkPrivate
                                                }
                                                errors={
                                                    errors.downloadLinkPrivate
                                                }
                                                isMobile={isMobile}
                                                caption={
                                                    'Please provide a link which will direct users to the upload post on your Patreon.'
                                                }
                                            />
                                            <DateTimeInput
                                                switchChecked={
                                                    switchCheckedPrivate
                                                }
                                                setSwitchChecked={
                                                    setSwitchCheckedPrivate
                                                }
                                                id={'scheduledAtPrivate'}
                                                label={'SCHEDULE RELEASE'}
                                                handleChange={setFieldValue}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.scheduledAtPrivate
                                                }
                                                errors={
                                                    errors.scheduledAtPrivate
                                                }
                                                value={
                                                    values.scheduledAtPrivate
                                                }
                                                caption={
                                                    'Your upload will appear available on the selected date/time once approved.'
                                                }
                                                disablePast={true}
                                                addDays={
                                                    standardReleaseAddedDays
                                                }
                                            />
                                        </>
                                    ) : null}

                                    {values.selectedDownloadLinkType ===
                                    'public' ? (
                                        <>
                                            <TextInput
                                                id={'downloadLinkPublic'}
                                                label={'PUBLIC DOWNLOAD LINK *'}
                                                value={
                                                    values.downloadLinkPublic
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.downloadLinkPublic
                                                }
                                                errors={
                                                    errors.downloadLinkPublic
                                                }
                                                isMobile={isMobile}
                                                caption={
                                                    'Please provide a Direct Download Link.'
                                                }
                                            />
                                            <DateTimeInput
                                                switchChecked={
                                                    switchCheckedPublic ||
                                                    (values.scheduledAtPublic !==
                                                        undefined &&
                                                        values.scheduledAtPublic !==
                                                            null)
                                                }
                                                setSwitchChecked={
                                                    setSwitchCheckedPublic
                                                }
                                                id={'scheduledAtPublic'}
                                                touched={
                                                    touched.scheduledAtPublic
                                                }
                                                errors={
                                                    errors.scheduledAtPublic
                                                }
                                                label={'SCHEDULE RELEASE'}
                                                handleChange={setFieldValue}
                                                hasSubmitted={allDataSubmitted}
                                                value={values.scheduledAtPublic}
                                                caption={
                                                    'Your upload will appear available on the selected date/time once approved.'
                                                }
                                                disablePast={true}
                                                addDays={
                                                    standardReleaseAddedDays
                                                }
                                            />
                                        </>
                                    ) : null}

                                    {values.selectedDownloadLinkType ===
                                    'both' ? (
                                        <>
                                            <TextInput
                                                id={'downloadLinkPrivate'}
                                                label={
                                                    'PATREON DOWNLOAD LINK *'
                                                }
                                                value={
                                                    values.downloadLinkPrivate
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.downloadLinkPrivate
                                                }
                                                errors={
                                                    errors.downloadLinkPrivate
                                                }
                                                isMobile={isMobile}
                                                caption={
                                                    'Please provide a link which will direct users to the upload post on your Patreon.'
                                                }
                                            />
                                            <DateTimeInput
                                                switchChecked={
                                                    switchCheckedPrivate
                                                }
                                                setSwitchChecked={
                                                    setSwitchCheckedPrivate
                                                }
                                                touched={
                                                    touched.scheduledAtPrivate
                                                }
                                                errors={
                                                    errors.scheduledAtPrivate
                                                }
                                                id={'scheduledAtPrivate'}
                                                label={'SCHEDULE RELEASE'}
                                                handleChange={setFieldValue}
                                                hasSubmitted={allDataSubmitted}
                                                value={
                                                    values.scheduledAtPrivate
                                                }
                                                caption={
                                                    'Your upload will appear available on the selected date/time once approved.'
                                                }
                                                disablePast={true}
                                                addDays={
                                                    standardReleaseAddedDays
                                                }
                                            />
                                            <TextInput
                                                id={'downloadLinkPublic'}
                                                label={'PUBLIC DOWNLOAD LINK *'}
                                                value={
                                                    values.downloadLinkPublic
                                                }
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={allDataSubmitted}
                                                touched={
                                                    touched.downloadLinkPublic
                                                }
                                                errors={
                                                    errors.downloadLinkPublic
                                                }
                                                isMobile={isMobile}
                                                caption={
                                                    'Please provide a Direct Download Link.'
                                                }
                                            />
                                            <DateTimeInput
                                                enableToggle={false}
                                                setSwitchChecked={
                                                    setSwitchCheckedPublic
                                                }
                                                id={'scheduledAtPublic'}
                                                label={'PUBLIC RELEASE DATE *'}
                                                touched={
                                                    touched.scheduledAtPublic
                                                }
                                                errors={
                                                    errors.scheduledAtPublic
                                                }
                                                handleChange={setFieldValue}
                                                hasSubmitted={allDataSubmitted}
                                                value={values.scheduledAtPublic}
                                                caption={
                                                    'Your upload will be available to the public on the selected date/time once approved.'
                                                }
                                                disablePast={true}
                                                minDate={
                                                    values.scheduledAtPrivate
                                                }
                                                addDays={
                                                    earlyAccessReleaseAddedDays
                                                }
                                            />
                                        </>
                                    ) : null}

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '1rem',
                                        }}
                                    >
                                        <Button
                                            type="submit"
                                            disabled={isSubmitting || !isValid}
                                            variant="contained"
                                            sx={{
                                                padding: '0.8rem',
                                            }}
                                        >
                                            UPLOAD
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="outlined"
                                            color="inherit"
                                            sx={{
                                                padding: '0.8rem',
                                                marginLeft: 2,
                                            }}
                                            onClick={() => handleBack(values)} // Pass values to handleBack
                                        >
                                            BACK
                                        </Button>
                                    </Box>
                                </Form>
                            )}
                        </Formik>
                    ) : null}
                </>
            ),
        },
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const topSectionInfo = (
        <>
            Before submitting content to <b>Discovery</b>, please ensure that
            you have read the&nbsp;
            <Typography
                component="span"
                variant="caption"
                sx={{ color: theme.palette.warning.main }}
            >
                terms and conditions&nbsp;
            </Typography>
        </>
    );

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box sx={{ position: 'relative', overflow: 'hidden' }}>
                <PageTopSection
                    isMobile={isMobile}
                    padding={padding}
                    simpleContent={{
                        title: 'UPLOAD',
                        info: topSectionInfo,
                    }}
                />

                {/* PAGE CONTENTS */}
                <Box
                    sx={{
                        paddingLeft: getOverallPadding(isMobile, padding),
                        paddingRight: getOverallPadding(isMobile, padding),
                        marginBottom: isMobile ? 10 : 3,
                    }}
                >
                    {loadingGames || loadingCollaborators ? null : (
                        <>
                            {error && <ErrorAlert message={error}></ErrorAlert>}

                            {success && (
                                <SuccessAlert message={success}></SuccessAlert>
                            )}

                            {allDataSubmitted && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 2,
                                        marginBottom: 2,
                                    }}
                                >
                                    <Box sx={{ flexGrow: 1 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={progress}
                                        />
                                    </Box>
                                    <Box sx={{ minWidth: 35 }}>
                                        <Typography
                                            variant="body2"
                                            color="textSecondary"
                                        >
                                            {`${Math.round(progress)}%`}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                            <Fade in={true} timeout={fadeTimeout}>
                                <Stepper
                                    activeStep={activeStep}
                                    orientation="vertical"
                                >
                                    {steps.map((step, index) => (
                                        <Step key={step.label}>
                                            <Fade
                                                in={true}
                                                timeout={fadeTimeout}
                                            >
                                                <div>
                                                    <StepLabel
                                                        StepIconComponent={(
                                                            props
                                                        ) => (
                                                            <CustomStepIcon
                                                                {...props}
                                                                iconElement={
                                                                    step.icon
                                                                }
                                                            />
                                                        )}
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                flexDirection:
                                                                    isMobile
                                                                        ? 'column'
                                                                        : 'row', // Change direction based on mobile view
                                                                alignItems:
                                                                    isMobile
                                                                        ? 'flex-start'
                                                                        : 'center', // Align items differently on mobile
                                                                paddingLeft:
                                                                    !isMobile
                                                                        ? 2
                                                                        : 0,
                                                            }}
                                                        >
                                                            <Typography
                                                                variant="h5"
                                                                sx={{
                                                                    color: theme
                                                                        .palette
                                                                        .text
                                                                        .primary,
                                                                    cursor: 'default',
                                                                }}
                                                            >
                                                                {step.label}
                                                            </Typography>
                                                            {/* Add step.info below the label in mobile view */}
                                                            <Typography
                                                                variant="body2"
                                                                sx={{
                                                                    marginLeft:
                                                                        isMobile
                                                                            ? 0.1
                                                                            : 1, // No left margin in mobile view
                                                                    color: theme
                                                                        .palette
                                                                        .text
                                                                        .secondary, // Keep the secondary color
                                                                    cursor: 'default',
                                                                }}
                                                            >
                                                                {step.info}
                                                            </Typography>
                                                        </Box>
                                                    </StepLabel>

                                                    <StepContent
                                                        sx={{
                                                            paddingLeft:
                                                                !isMobile
                                                                    ? 5
                                                                    : 3.2,
                                                        }}
                                                    >
                                                        <Card>
                                                            <CardContent>
                                                                {step.content}
                                                            </CardContent>
                                                        </Card>
                                                    </StepContent>
                                                </div>
                                            </Fade>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Fade>
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default CreateUploadPage;
