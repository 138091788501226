import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, Grid, Stack, Link } from '@mui/material';
import { ToolBarColor, getOverallPadding } from '../../helpers/Themes';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { alpha } from '@mui/system';
import Socials, { SocialLinksUserProfile } from '../../helpers/Socials';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { GetFooterStatsResponse } from '../../workers/ApiWorker';
import TermsModal from '../modal/TermsModal';
import PolicyModal from '../modal/PolicyModal';

interface FooterProps {
    isMobile: boolean;
    padding?: number;
}

const Footer: React.FC<FooterProps> = ({ isMobile, padding }) => {
    const { theme, themeType } = useCustomTheme();

    const [isTermsModalOpen, setIsTermsModalOpen] =
        React.useState<boolean>(false);
    const [isPolicyModalOpen, setIsPolicyModalOpen] =
        React.useState<boolean>(false);

    const showTermsModal = () => {
        setIsTermsModalOpen(true);
    };

    const closeTermsModal = () => {
        setIsTermsModalOpen(false);
    };

    const showPolicyModal = () => {
        setIsPolicyModalOpen(true);
    };

    const closePolicyModal = () => {
        setIsPolicyModalOpen(false);
    };

    const createApiWorker = createWorkerFactory(
        () => import('../../workers/ApiWorker')
    );

    const apiWorker = useWorker(createApiWorker);

    const [stats, setStats] = useState<GetFooterStatsResponse>({
        data: {
            users: 0,
            uploads: 0,
            hearts: 0,
        },
    } as GetFooterStatsResponse);

    useEffect(() => {
        const loadStats = async () => {
            const fetchedStats = await apiWorker.getFooterStats();
            setStats(fetchedStats);
        };

        loadStats();
    }, [apiWorker]);

    return (
        <Box
            sx={{
                bgcolor: ToolBarColor,
                ...(themeType === 'custom' && {
                    boxShadow: 'none',
                    backgroundImage: 'none',
                }),
                borderTop: (theme) =>
                    `1px solid ${alpha(theme.palette.primary.light, 1)}`,
                p: 2,
                textAlign: 'center',
                marginTop: 'auto',
                position: 'relative',
            }}
        >
            <Box
                sx={{
                    paddingLeft: getOverallPadding(isMobile, padding),
                    paddingRight: getOverallPadding(isMobile, padding),
                }}
            >
                <Grid
                    container
                    spacing={3}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {/* Left Section - Support & Network Metrics */}
                    <Grid item sm={12} textAlign={{ xs: 'center', md: 'left' }}>
                        <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{ pt: 2, cursor: 'default' }}
                        >
                            SUPPORT DISCOVERY
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        spacing={3}
                        alignItems="center"
                        sx={{ mt: 1 }}
                    >
                        <Grid container spacing={3} alignItems="center">
                            <Grid
                                item
                                sm={6}
                                textAlign="center" // You can use 'center' to align the button inside the grid.
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    sx={{ mt: 0.5, ml: 6 }}
                                    component="a"
                                    href={
                                        SocialLinksUserProfile.patreon.link +
                                        'dotdiscovery'
                                    } // Replace this with the URL you want to open
                                    target="_blank" // This will open the link in a new window or tab
                                    rel="noopener noreferrer" // For security reasons (prevents some potential issues with target="_blank")
                                >
                                    GET PREMIUM
                                </Button>
                            </Grid>

                            <Grid
                                item
                                sm={6}
                                textAlign="center"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                {/* Content for the right half */}
                                <Socials
                                    twitter="dot_discovery"
                                    patreon="dotdiscovery"
                                    discord="dotdiscovery"
                                    // blueSky="dotdiscovery.bsky.social"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} textAlign={{ xs: 'center', md: 'left' }}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: 'fit-content',
                            }}
                        >
                            <Box sx={{ mt: 1, cursor: 'default' }}>
                                <Typography
                                    variant="subtitle1"
                                    fontWeight="bold"
                                    sx={{
                                        color: theme.palette.warning.main,
                                        marginTop: 0.2,
                                        position: 'relative',
                                        mb: 1,
                                    }}
                                >
                                    NETWORK METRICS
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={3}
                                    justifyContent={{
                                        xs: 'center',
                                        md: 'flex-start',
                                    }}
                                    sx={{ mb: 6 }}
                                >
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            MEMBERS
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {stats?.data?.users ?? 0}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            MODS
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {stats?.data?.uploads ?? 0}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            HEARTS
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                        >
                                            {stats?.data?.hearts ?? 0}
                                        </Typography>
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

                {/* Footer Bottom - Copyright & Links */}
                <Box
                    display="flex"
                    justifyContent="space-between"
                    flexWrap="wrap"
                    textAlign="center"
                >
                    <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ cursor: 'default' }}
                    >
                        &copy; {new Date().getFullYear()} dot.Discovery. All
                        rights reserved. Trademarks and registered trademarks
                        belong to their respective owners.
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Link
                            variant="body2"
                            sx={{
                                color: theme.palette.text.secondary,
                                cursor: 'pointer', // Ensure it looks clickable
                            }}
                            onClick={showTermsModal} // Removed parentheses
                        >
                            Terms of Service
                        </Link>
                        <Link
                            variant="body2"
                            sx={{
                                color: theme.palette.text.secondary,
                                cursor: 'pointer', // Ensure it looks clickable
                            }}
                            onClick={showPolicyModal} // Removed parentheses
                        >
                            Privacy Policy
                        </Link>
                    </Stack>
                </Box>
            </Box>

            <TermsModal
                closeModal={closeTermsModal}
                isModalOpen={isTermsModalOpen}
                isMobile={isMobile}
            />

            <PolicyModal
                closeModal={closePolicyModal}
                isModalOpen={isPolicyModalOpen}
                isMobile={isMobile}
            />
        </Box>
    );
};

export default Footer;
