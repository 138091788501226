import React from 'react';
import {
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import Swipe from '../universal/swipe/Swipe';
import { useCustomTheme } from '../../contexts/ThemeContext';
import { upperCaseFirst } from 'upper-case-first';
import Box from '@mui/material/Box';
import { FilterAlt } from '@mui/icons-material';
import { useApi } from '../../contexts/ApiContext';
import { User } from '../../workers/ApiWorker';

interface SearchBarInputProps {
    isMobile: boolean;
    filters: () => { text: string; value: string; icon: JSX.Element }[];
    selectedFilter: string;
    handleSelectedFilter: (value: string) => void;
    padding?: number;
    user?: User;
    isLoading?: boolean;
}

const Filters: React.FC<SearchBarInputProps> = ({
    isMobile,
    selectedFilter,
    filters,
    handleSelectedFilter,
    padding,
    user,
    isLoading,
}) => {
    const { token } = useApi();
    const { theme } = useCustomTheme();

    const [active, setActive] = React.useState(selectedFilter);
    const [swipeOpen, setSwipeOpen] = React.useState(false);

    const handleClickValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        setActive(event.target.value);
        handleSelectedFilter(event.target.value);
    };

    const handleClickValueTab = (label: string) => {
        setActive(label);
        handleSelectedFilter(label);
    };

    const handleClickSwipe = (value: boolean) => {
        setSwipeOpen(value);
    };

    const radioFilters = () => {
        return (
            <FormControl>
                <RadioGroup
                    aria-labelledby="sort-radio-buttons-group"
                    value={active} // Make it a controlled component
                    name="sort-radio-buttons-group"
                    onChange={handleClickValue} // Add onChange to handle radio change
                >
                    {filters().map((label) => {
                        // Check if the filter should be displayed based on 'coming-soon' and user/token
                        return (
                            <FormControlLabel
                                key={label.value}
                                value={label.value}
                                control={<Radio />}
                                label={label.text}
                                sx={{ color: theme.palette.text.secondary }} // Set font color
                                disabled={active === label.value || isLoading}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        );
    };

    return !isMobile ? (
        <Tabs
            value={active}
            centered={false} // Ensure the tabs are not centered
            sx={{
                textAlign: 'left',
                justifyContent: 'flex-start',
                paddingLeft: padding ? padding : undefined,
                '& .MuiTabs-indicator': {
                    height: '6px', // Make the indicator thicker
                },
            }}
        >
            {filters().map((label) => {
                return (
                    <Tab
                        value={label.value}
                        key={label.value}
                        label={label.text}
                        onClick={() => handleClickValueTab(label.value)}
                        sx={{
                            fontSize: '1.1rem',
                            marginRight: 1.8,
                            marginBottom: 1,
                            textTransform: 'none', // Prevent uppercase transformation
                            color: theme.palette.text.disabled,
                            '&.Mui-selected': {
                                color: theme.palette.text.secondary,
                            },
                            display: 'flex',
                            alignItems: 'center', // Ensures all tabs are centered vertically
                        }}
                        disabled={active === label.value || isLoading}
                    />
                );
            })}
        </Tabs>
    ) : (
        <>
            <span
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center', // Ensures vertical alignment of both items
                    flexDirection: 'column', // Stack the content vertically
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                        }}
                    >
                        <FilterAlt
                            style={{
                                color: theme.palette.primary.main,
                                cursor: 'pointer',
                            }}
                            onClick={() => handleClickSwipe(true)}
                        />
                        <Typography
                            variant="h4"
                            onClick={() => handleClickSwipe(true)} // Still open swipe on click, but no change for the filter
                        >
                            {active
                                .split('-')
                                .map((word) => upperCaseFirst(word))
                                .join(' ')}
                        </Typography>
                    </div>
                </Box>
            </span>

            <Swipe
                open={swipeOpen}
                handleSwipeOpen={handleClickSwipe}
                title={'Sort'}
                body={radioFilters}
            />
        </>
    );
};

export default Filters;
