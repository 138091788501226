import React, { useState } from 'react';
import {
    FormControl,
    Grid,
    Button,
    Switch,
    FormControlLabel,
    FormHelperText,
} from '@mui/material';
import LabelInput from './LabelInput';
import CaptionInput from './CaptionInput';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import {
    DateTimeValidationError,
    PickerChangeHandlerContext,
} from '@mui/x-date-pickers';
import { useCustomTheme } from '../../../contexts/ThemeContext';

interface DateTimeInputProps {
    id: string;
    label: string;
    value?: string;
    handleChange: (field: string, value: any) => void;
    hasSubmitted: boolean;
    enableLabel?: boolean;
    isMobile?: boolean;
    caption?: string | JSX.Element;
    disablePast?: boolean;
    enableToggle?: boolean;
    showClearButton?: boolean;
    switchChecked?: boolean;
    setSwitchChecked?: (checked: boolean) => void;
    errors?: string;
    touched?: boolean;
    minDate?: string;
    addDays?: number;
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
    id,
    label,
    handleChange,
    hasSubmitted,
    value,
    enableLabel,
    isMobile,
    caption,
    disablePast,
    enableToggle = true,
    showClearButton = false,
    switchChecked = true,
    setSwitchChecked,
    touched,
    errors,
    minDate,
    addDays = 0,
}) => {
    const { theme } = useCustomTheme();

    const [open, setOpen] = useState(false); // To control opening of picker
    const [dateValue, setDateValue] = useState<string | undefined>(value);
    const [isValid, setIsValid] = useState<boolean>(true);

    const handleOpenPicker = () => {
        setOpen(true); // Open the picker
    };

    const handleClosePicker = () => {
        setOpen(false); // Close the picker if needed
    };

    const handleChangeDate = (
        newValue: Dayjs | null,
        context: PickerChangeHandlerContext<DateTimeValidationError>
    ) => {
        if (newValue) {
            if (newValue.isValid()) {
                // Compare with today's date (ignoring time)
                const today = dayjs().startOf('day'); // Get today's date at midnight
                if (newValue.isSame(today, 'day')) {
                    const tomorrow = today.add(1, 'day'); // Add 1 day to today
                    const timestamp = tomorrow.toISOString(); // Convert to ISO string

                    setIsValid(true);
                    setDateValue(timestamp);
                    handleChange(id, timestamp); // Trigger handleChange with tomorrow's date
                } else {
                    setIsValid(true);
                    const timestamp = newValue.toISOString();
                    setDateValue(timestamp);
                    handleChange(id, timestamp);
                }
            } else {
                handleClear(false);
            }
        } else {
            handleClear(true);
        }
    };

    const handleClear = (isItValid: boolean) => {
        setDateValue(undefined);
        setIsValid(isItValid);
        handleChange(id, null);
    };

    return (
        <FormControl fullWidth margin="normal">
            <Grid
                container
                alignItems={isMobile ? 'flex-start' : 'center'}
                spacing={2}
            >
                {!enableLabel && (
                    <LabelInput
                        label={label}
                        enableLabel={enableLabel}
                        isMobile={isMobile}
                        caption={caption}
                    />
                )}
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={isMobile ? 12 : enableLabel ? 12 : 9.5}
                    lg={enableLabel ? 12 : 9.8}
                    xl={enableLabel ? 12 : 10.25}
                >
                    {enableToggle && (
                        <FormControlLabel
                            control={
                                <Switch
                                    onChange={(e) => {
                                        if (setSwitchChecked) {
                                            setSwitchChecked(e.target.checked); // Update the switch state
                                        }

                                        handleClear(e.target.checked); // Pass switch state to handleClear
                                    }}
                                    checked={switchChecked}
                                />
                            }
                            label={!enableLabel ? undefined : label}
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                            }} // Align the label and switch to the left
                        />
                    )}

                    {switchChecked && (
                        <>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DateTimePicker']}>
                                    <DateTimePicker
                                        minDate={
                                            minDate
                                                ? dayjs(minDate).add(
                                                      addDays,
                                                      'day'
                                                  )
                                                : dayjs().add(addDays, 'day')
                                        }
                                        timezone={'system'}
                                        minutesStep={30}
                                        disablePast={disablePast}
                                        disabled={hasSubmitted}
                                        label={enableLabel ? label : undefined}
                                        onChange={handleChangeDate}
                                        value={
                                            dateValue ? dayjs(dateValue) : null
                                        }
                                        slotProps={{
                                            textField: {
                                                inputProps: {
                                                    readOnly: true,
                                                    placeholder: '',
                                                    value: dateValue
                                                        ? dayjs(
                                                              dateValue
                                                          ).format(
                                                              'DD MMM YYYY hh:mm A'
                                                          )
                                                        : '', // Custom format
                                                },
                                                onClick: handleOpenPicker,
                                            },
                                        }}
                                        shouldDisableDate={(date) =>
                                            date.isSame(dayjs(), 'day')
                                        }
                                        format="DD MMM YYYY hh:mm A"
                                        open={open} // Control opening the picker
                                        onOpen={handleOpenPicker} // Handle opening the picker
                                        onClose={handleClosePicker} // Handle closing the picker
                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                            {/* Display errors below the radio buttons */}
                            {errors && (
                                <FormHelperText
                                    sx={{
                                        color: theme.palette.error.main,
                                        marginTop: 1, // Add space between radio buttons and errors
                                    }}
                                >
                                    {errors}
                                </FormHelperText>
                            )}
                            {enableLabel && caption && (
                                <CaptionInput caption={caption} />
                            )}
                            {showClearButton ? (
                                <Button
                                    disabled={dateValue === undefined}
                                    onClick={() => handleClear(true)}
                                    variant="outlined"
                                    color="warning"
                                    sx={{ mb: 2, mt: 1 }}
                                >
                                    CLEAR {label}
                                </Button>
                            ) : null}
                        </>
                    )}
                </Grid>
            </Grid>
        </FormControl>
    );
};

export default DateTimeInput;
