import React, { useEffect, useState } from 'react';
import './App.css';
import TopAppBar from './components/navigation/navbar/TopAppBar';
import { ThemeProvider, CssBaseline, Fade, Button } from '@mui/material';
import Box from '@mui/material/Box';
import SideBarMobile from './components/navigation/sidebar/SideBarMobile';
import AppRoutes from './components/AppRoutes';
import { ApiProvider, CustomJwtPayload, useApi } from './contexts/ApiContext';
import { CustomThemeProvider, useCustomTheme } from './contexts/ThemeContext';
import BottomProfileBar from './components/navigation/navbar/BottomProfileBar';
import '@fontsource/archivo';
import '@fontsource/archivo/300.css'; // Light weight
import '@fontsource/archivo/400.css'; // Regular
import '@fontsource/archivo/500.css'; // Medium
import '@fontsource/archivo/600.css'; // Semi-Bold
import '@fontsource/archivo/700.css'; // Bold
import '@fontsource/archivo/800.css'; // Extra Bold
import Footer from './components/footer/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { fadeTimeout, getOverallPadding } from './helpers/Themes';
import {
    resendVerification,
    User,
    UserAccountTypes,
} from './workers/ApiWorker';
import Cookies from 'universal-cookie';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import { jwtDecode } from 'jwt-decode';
import {
    canUserDirectlyUploadAndNotifyDiscord,
    isUserSuspended,
    isUserVerified,
} from './helpers/Users';
import WarningAlert from './components/universal/alerts/WarningAlert';
import VerifyAlert from './components/universal/alerts/VerifyAlert';
import axios, { isAxiosError } from 'axios';

const createApiWorker = createWorkerFactory(
    () => import('./workers/ApiWorker')
);

function App() {
    const navigate = useNavigate();

    const apiWorker = useWorker(createApiWorker);
    const mobileWidth = 899;
    //const mobileWidth = 1046;
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = useState<boolean>(width <= mobileWidth);

    const cookies = new Cookies();

    const [showUnverifiedBanner, setShowUnverifiedBanner] =
        useState<boolean>(false);

    const [tokenForVerification, setTokenForVerification] = useState<
        string | undefined
    >();

    const [user, setUser] = useState<User | undefined>(undefined);

    const [isVerificationSent, setIsVerificationSent] =
        useState<boolean>(false);

    const cacheKey = 'resendVerificationCache'; // The key for our cookie

    const loadVerificationStatus = () => {
        const cachedStatus = cookies.get(cacheKey);

        if (cachedStatus === true) {
            // If the value in the cookie is 'true', update the state
            setIsVerificationSent(true);
        } else {
            // If it's 'false' or doesn't exist, keep the state as false
            setIsVerificationSent(false);
        }
    };

    useEffect(() => {
        const removeScrollbarPadding = () => {
            // If the window has a vertical scrollbar, reset padding-right
            if (
                document.documentElement.scrollHeight >
                document.documentElement.clientHeight
            ) {
                document.body.style.paddingRight = '0'; // Reset padding-right to 0
            }
        };

        // Call the function when the component mounts
        removeScrollbarPadding();

        // Optional: Check again on window resize to ensure no layout shift
        window.addEventListener('resize', removeScrollbarPadding);

        // Clean up event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', removeScrollbarPadding);
        };
    }, []);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
        setIsMobile(window.innerWidth <= mobileWidth);
    }

    const location = useLocation(); // Get the current route
    const hideLayout = [
        '/login',
        '/register',
        '/reset-password',
        '/forgot-password',
        '/verify-account',
        '/suspended',
    ].includes(location.pathname);

    const locationRequiresToken = [
        '/upload',
        '/admin',
        '/file-upload',
        '/notifications',
        '/verify-account',
    ].includes(location.pathname);

    const locationRequiresVerification = ['/upload'].includes(
        location.pathname
    );

    const locationRequiresNoToken = [
        '/login',
        '/register',
        '/reset-password',
        '/forgot-password',
    ].includes(location.pathname);

    const locationRequiresUserFileUpload = ['/file-upload'].includes(
        location.pathname
    );

    const locationRequiresAdminModerator = ['/admin'].includes(
        location.pathname
    );

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    // Calculate padding based on width
    const calculatePadding = () => {
        // Was previously 10
        return 15;
    };

    const padding = calculatePadding(); // Dynamically set padding

    useEffect(() => {
        const forceLogout = async (): Promise<boolean> => {
            cookies.remove('token');
            //setToken(undefined);
            setUser(undefined);
            return true;
        };

        const functionGetMe = async (
            myToken: string
        ): Promise<User | null | 'SUSPENDED'> => {
            try {
                const response = await apiWorker.getUserMe(myToken);
                return response.data;
            } catch (error) {
                if (
                    isAxiosError(error) &&
                    error.response?.data.message === 'This user is suspended'
                ) {
                    return 'SUSPENDED';
                }

                return null;
            }
        };

        const functionHasMyTokenExpired = async (
            myToken: string
        ): Promise<boolean> => {
            const decodedToken = jwtDecode(myToken) as CustomJwtPayload;
            // We need to check if expired etc
            // Get the current timestamp in seconds
            const currentTimestamp = Math.floor(Date.now() / 1000);

            if (decodedToken.exp) {
                if (decodedToken.exp > currentTimestamp) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        const getMyCachedToken = cookies.get('token');
        //setToken(getMyCachedToken);

        // Does this location require a token and there is no token for user
        if (locationRequiresToken && !getMyCachedToken) {
            navigate('/');
        }

        // Does this location require no token and there is a token for the user
        if (locationRequiresNoToken && getMyCachedToken) {
            navigate('/');
        }

        if (!getMyCachedToken && location.pathname === '/suspended') {
            navigate('/');
        }

        if (!getMyCachedToken) {
            // Success (No token)
            setUser(undefined);
        } else {
            // we need to check if the token has expired
            functionHasMyTokenExpired(getMyCachedToken)
                .then((hasExpired) => {
                    if (!hasExpired) {
                        functionGetMe(getMyCachedToken)
                            .then((myUser) => {
                                // if we got a user, check if they are suspended
                                if (myUser !== null) {
                                    if (myUser === 'SUSPENDED') {
                                        if (
                                            location.pathname !== '/suspended'
                                        ) {
                                            navigate('/suspended');
                                        }
                                    } else {
                                        if (isUserSuspended(myUser)) {
                                            if (
                                                location.pathname !==
                                                '/suspended'
                                            ) {
                                                navigate('/suspended');
                                            }
                                        }

                                        if (
                                            location.pathname === '/suspended'
                                        ) {
                                            navigate('/');
                                        }

                                        if (
                                            !canUserDirectlyUploadAndNotifyDiscord(
                                                myUser
                                            ) &&
                                            locationRequiresUserFileUpload
                                        ) {
                                            navigate('/');
                                        }

                                        if (
                                            myUser.userProfile.accountType ===
                                                UserAccountTypes.MEMBER &&
                                            locationRequiresAdminModerator
                                        ) {
                                            navigate('/');
                                        }

                                        if (
                                            locationRequiresVerification &&
                                            !isUserVerified(
                                                getMyCachedToken,
                                                myUser
                                            )
                                        ) {
                                            navigate('/');
                                        }

                                        if (
                                            getMyCachedToken &&
                                            myUser &&
                                            !isUserVerified(
                                                getMyCachedToken,
                                                myUser
                                            )
                                        ) {
                                            loadVerificationStatus();
                                            setShowUnverifiedBanner(true);
                                            setTokenForVerification(
                                                getMyCachedToken
                                            );
                                        } else {
                                            setShowUnverifiedBanner(false);
                                        }

                                        setUser(myUser);
                                    }
                                } else {
                                    forceLogout().then((r) => {});
                                    console.log(
                                        'no user redirect to a page with a code'
                                    );
                                }
                            })
                            .catch((error) => {
                                forceLogout().then((r) => {});
                                console.log(
                                    'could not get user, redirect to a page with a code'
                                );
                            });
                    } else {
                        forceLogout().then((r) => {});
                        console.log(
                            'token expired, redirect to a page with a code'
                        );
                    }
                })
                .catch((error) => {
                    forceLogout().then((r) => {});
                    console.log(
                        'Errored checking token, redirect to a page with a code'
                    );
                });
        }
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    // const drawerWidth = 240;
    const drawerWidth = 80;
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);

    const handleMobileDrawerOpen = () => {
        setMobileDrawerOpen(true);
    };

    const handleMobileDrawerClose = () => {
        setMobileDrawerOpen(false);
    };

    const onResendVerify = async () => {
        if (tokenForVerification) {
            try {
                await apiWorker.resendVerification(tokenForVerification);

                const expiresIn1Hour = new Date();
                // Add 4 hours to the current date
                expiresIn1Hour.setHours(expiresIn1Hour.getHours() + 1);

                cookies.set(cacheKey, 'true', {
                    expires: expiresIn1Hour,
                });
                // Update the state to show that the verification has been sent
                setIsVerificationSent(true);
            } catch (error) {
                console.error('Error attempting to resend verification');
            }
        }
    };

    return (
        <ApiProvider>
            <CustomThemeProvider>
                <ThemeProvider theme={useCustomTheme().theme}>
                    <Fade in={true} timeout={fadeTimeout}>
                        <div>
                            <div className="App">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        minHeight: '100vh',
                                    }}
                                >
                                    <CssBaseline />
                                    {!hideLayout && isMobile && (
                                        <SideBarMobile
                                            drawerOpen={mobileDrawerOpen}
                                            handleDrawerClose={
                                                handleMobileDrawerClose
                                            }
                                            drawerWidth={drawerWidth}
                                            user={user}
                                        />
                                    )}

                                    <Box
                                        component="main"
                                        sx={{
                                            flexGrow: 1,
                                            overflow: 'hidden',
                                            marginTop: 10,
                                        }}
                                    >
                                        {!hideLayout && (
                                            <TopAppBar
                                                isMobile={isMobile}
                                                handleDrawerOpen={
                                                    handleMobileDrawerOpen
                                                }
                                                handleDrawerClose={
                                                    handleMobileDrawerClose
                                                }
                                                drawerOpen={mobileDrawerOpen}
                                                over={false}
                                                user={user}
                                            />
                                        )}
                                        {showUnverifiedBanner &&
                                        !['/verify-account'].includes(
                                            location.pathname
                                        ) ? (
                                            <Box
                                                sx={{
                                                    position: 'relative',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        paddingLeft:
                                                            getOverallPadding(
                                                                isMobile,
                                                                padding
                                                            ),
                                                        paddingRight:
                                                            getOverallPadding(
                                                                isMobile,
                                                                padding
                                                            ),
                                                    }}
                                                >
                                                    <VerifyAlert>
                                                        {isVerificationSent ? (
                                                            'Verification email has been resent successfully. Still no email? Please contact the staff in the Discovery Discord.'
                                                        ) : (
                                                            <>
                                                                Your account is
                                                                still
                                                                unverified.
                                                                Please check
                                                                your email and
                                                                verify your
                                                                account to gain
                                                                full access.
                                                                Don't forget to
                                                                check your spam
                                                                folder. If no
                                                                email has been
                                                                sent, please
                                                                click{' '}
                                                                <Button
                                                                    variant="text"
                                                                    color="primary"
                                                                    onClick={
                                                                        onResendVerify
                                                                    }
                                                                    sx={{
                                                                        textTransform:
                                                                            'none',
                                                                        padding: 0,
                                                                        minWidth:
                                                                            'auto',
                                                                    }}
                                                                >
                                                                    HERE
                                                                </Button>{' '}
                                                                to resend.
                                                            </>
                                                        )}
                                                    </VerifyAlert>
                                                </Box>
                                            </Box>
                                        ) : null}
                                        <AppRoutes
                                            isMobile={isMobile}
                                            padding={padding}
                                            user={user}
                                            width={width}
                                        ></AppRoutes>
                                        {!hideLayout && isMobile && (
                                            <BottomProfileBar
                                                isMobile={isMobile}
                                                user={user}
                                            />
                                        )}
                                    </Box>
                                </Box>
                            </div>
                            {!hideLayout && !isMobile && (
                                <Footer isMobile={isMobile} padding={padding} />
                            )}

                            {isMobile && hideLayout && (
                                <Box sx={{ marginBottom: 2 }} />
                            )}
                        </div>
                    </Fade>
                </ThemeProvider>
            </CustomThemeProvider>
        </ApiProvider>
    );
}

export default App;
