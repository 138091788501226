import Box from '@mui/material/Box';
import React from 'react';
import { Fade, Typography } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import { fadeTimeout } from '../../../helpers/Themes';

interface NoResultsFoundProps {
    ignore?: boolean;
}

// Correctly typed functional component
const NoResultsFound: React.FC<NoResultsFoundProps> = ({ ignore }) => {
    const { theme } = useCustomTheme();

    return ignore ? null : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
            }}
        >
            <Fade in={true} timeout={fadeTimeout}>
                <Typography
                    variant="h4"
                    sx={{
                        color: theme.palette.text.secondary,
                        cursor: 'default',
                    }}
                    gutterBottom
                >
                    No Results Found
                </Typography>
            </Fade>
        </Box>
    );
};

export default NoResultsFound;
