import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Typography, Link, Fade } from '@mui/material';
import logo from '../images/discovery-logo.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import ErrorAlert from '../components/universal/alerts/ErrorAlert';
import TextInput from '../components/universal/inputs/TextInput';
import { fadeTimeout } from '../helpers/Themes';
import { useCustomTheme } from '../contexts/ThemeContext';
import { createWorkerFactory, useWorker } from '@shopify/react-web-worker';
import SuccessAlert from '../components/universal/alerts/SuccessAlert';
import {
    POST_RESET_PASSWORD_FAILED,
    POST_RESET_PASSWORD_SUCCESS,
} from '../helpers/Messages';
import ReCAPTCHA from 'react-google-recaptcha';

const validationSchema = Yup.object({
    password: Yup.string()
        .min(10, 'Password should be at least 10 characters')
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/,
            'Password must contain at least 1 uppercase, 1 lowercase, and 1 number'
        )
        .required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Confirm Password is required'),
});

const createApiWorker = createWorkerFactory(
    () => import('../workers/ApiWorker')
);

const ResetPasswordPage: React.FC = () => {
    const recaptchaRef = useRef<ReCAPTCHA>(null);
    const { theme } = useCustomTheme();
    const navigate = useNavigate();
    const apiWorker = useWorker(createApiWorker);
    const [error, setError] = React.useState<string>('');
    const [showError, setShowError] = React.useState<boolean>(false);
    const [success, setSuccess] = React.useState<string>('');

    const [paramsError, setParamsError] = React.useState<boolean>(false);

    // Get search params from URL
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token'); // Get 'code' from query string

    const [captchaValue, setCaptchaValue] = useState<string | null>(null);

    const handleCaptchaChange = (value: string | null) => {
        setCaptchaValue(value);
    };
    //  const email = searchParams.get('email');

    useEffect(() => {
        if (!token) {
            setParamsError(true);
        }

        // if (!token || !email) {
        //     setParamsError(true);
        // }
    }, [token]); // Run whenever `code` changes or is first fetched

    return (
        <Fade in={true} timeout={fadeTimeout}>
            <Box
                sx={{
                    height: '90vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 2,
                    flexDirection: 'column',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: '2rem',
                        width: '100%',
                        maxWidth: '400px',
                        minHeight: '350px',
                    }}
                >
                    {/* Logo */}
                    <Box
                        component="img"
                        src={logo}
                        alt="Logo"
                        sx={{ width: '80px', marginBottom: '2rem' }}
                    />

                    {paramsError ? (
                        <>
                            <Typography
                                variant="h2"
                                sx={{ fontWeight: 700, marginBottom: '1rem' }}
                            >
                                ERROR
                            </Typography>

                            {/* Subtitle */}
                            <Typography
                                variant="body2"
                                sx={{
                                    marginBottom: '2rem',
                                    color: theme.palette.text.disabled,
                                }}
                            >
                                <Link
                                    href="/login"
                                    underline="hover"
                                    variant="body2"
                                    sx={{
                                        color: theme.palette.text.disabled,
                                    }}
                                >
                                    <i>
                                        Something strange has happened, please
                                        click <b>here</b> to return to the login
                                        page.
                                    </i>
                                </Link>
                            </Typography>
                        </>
                    ) : (
                        <>
                            {/* Title */}
                            <Typography
                                variant="h2"
                                sx={{
                                    fontWeight: 700,
                                    marginBottom: '0rem',
                                    cursor: 'default',
                                }}
                            >
                                RESET PASSWORD
                            </Typography>

                            {/* Subtitle */}
                            <Typography
                                variant="body2"
                                sx={{
                                    marginBottom: '2rem',
                                    color: theme.palette.text.disabled,
                                    cursor: 'default',
                                }}
                            >
                                please enter a new password
                            </Typography>

                            {showError && <ErrorAlert message={error} />}

                            {success !== '' ? (
                                <>
                                    <SuccessAlert message={success} />
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        sx={{
                                            padding: '0.8rem',
                                            marginBottom: 1.8,
                                        }}
                                        onClick={() => {
                                            navigate('/login');
                                        }}
                                    >
                                        LOGIN
                                    </Button>
                                </>
                            ) : (
                                <Formik
                                    initialValues={{
                                        password: '',
                                        confirmPassword: '',
                                    }}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        if (
                                            token &&
                                            captchaValue &&
                                            recaptchaRef.current
                                        ) {
                                            setShowError(false);
                                            setSuccess('');
                                            try {
                                                await apiWorker.resetPassword(
                                                    token,
                                                    values.password,
                                                    captchaValue
                                                );
                                                setSuccess(
                                                    POST_RESET_PASSWORD_SUCCESS
                                                );
                                                resetForm();
                                            } catch (error) {
                                                setError(
                                                    POST_RESET_PASSWORD_FAILED
                                                );
                                                setShowError(true);
                                                setCaptchaValue(null);
                                                recaptchaRef.current.reset();
                                            }
                                        }
                                    }}
                                >
                                    {({
                                        errors,
                                        touched,
                                        values,
                                        handleChange,
                                        isSubmitting,
                                        handleBlur,
                                        isValid,
                                    }) => (
                                        <Form style={{ width: '100%' }}>
                                            <TextInput
                                                id="password"
                                                label="Password *"
                                                value={values.password}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={touched.password}
                                                errors={errors.password}
                                                type="password"
                                                enableLabel={true}
                                            />

                                            <TextInput
                                                id="confirmPassword"
                                                label="Confirm Password *"
                                                value={values.confirmPassword}
                                                handleChange={handleChange}
                                                handleBlur={handleBlur}
                                                hasSubmitted={isSubmitting}
                                                touched={
                                                    touched.confirmPassword
                                                }
                                                errors={errors.confirmPassword}
                                                type="password"
                                                enableLabel={true}
                                            />

                                            {/* Forgot Password Link */}
                                            <Box
                                                sx={{
                                                    textAlign: 'right',
                                                    alignSelf: 'flex-end',
                                                    marginBottom: '1rem',
                                                }}
                                            >
                                                <Link
                                                    href="/login"
                                                    underline="hover"
                                                    variant="body2"
                                                    sx={{
                                                        color: theme.palette
                                                            .warning.main,
                                                    }}
                                                >
                                                    <i>
                                                        Remembered your
                                                        password?{' '}
                                                    </i>
                                                    <i>
                                                        <b>Login!</b>
                                                    </i>
                                                </Link>
                                            </Box>

                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center', // Centers horizontally
                                                    alignItems: 'center', // Centers vertically
                                                    marginBottom: 2,
                                                }}
                                            >
                                                <ReCAPTCHA
                                                    ref={recaptchaRef}
                                                    sitekey={
                                                        process.env
                                                            .REACT_APP_RECAPTCHA_SITE_KEY as string
                                                    }
                                                    onChange={
                                                        handleCaptchaChange
                                                    }
                                                />
                                            </Box>

                                            <Button
                                                fullWidth
                                                type="submit"
                                                disabled={
                                                    isSubmitting ||
                                                    captchaValue === null ||
                                                    !isValid
                                                }
                                                variant="contained"
                                                sx={{
                                                    padding: '0.8rem',
                                                    marginBottom: 1.8,
                                                }}
                                            >
                                                RESET
                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </Fade>
    );
};

export default ResetPasswordPage;
