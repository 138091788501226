import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {
    Game,
    Upload,
    UploadStatus,
    UploadType,
    User,
} from '../../../workers/ApiWorker';
import { alpha, CardContent } from '@mui/material';
import { useCustomTheme } from '../../../contexts/ThemeContext';
import Box from '@mui/material/Box';
import {
    Block,
    Cancel,
    HourglassTop,
    NewReleases,
    Pending,
} from '@mui/icons-material';
import {
    OrangeColor,
    TextCardPreviewDescriptionColor,
} from '../../../helpers/Themes';
import NoImage from '../../upload/NoImage';
import {
    calculateTimeLeft,
    getUploadStatus,
    hasOpenReports,
    isUploadPublic,
    removalDay,
    renderTimeLeft,
    TimeLeftType,
} from '../../../helpers/Uploads';
import IconButton from '@mui/material/IconButton';
import { SiPatreon } from 'react-icons/si';
import PreviewCardStatusBanner from './PreviewCardStatusBanner';
import { addDays } from 'date-fns';
import PatreonIcon from '../icons/PatreonIcon';

interface PreviewCardProps {
    uuid?: string;
    username?: string;
    title: string;
    description: string;
    image: string | null;
    games: Game[];
    type: UploadType;
    upload?: Upload;
    user?: User;
    handleReuseDetails?: (upload: Upload) => void;
}

const PreviewCard: React.FC<PreviewCardProps> = ({
    uuid,
    username,
    title,
    description,
    image,
    games,
    type,
    upload,
    user,
    handleReuseDetails,
}) => {
    const navigate = useNavigate(); // Initialize useNavigate
    const [imgError, setImgError] = useState(false);
    const { theme, themeType } = useCustomTheme();

    const handleClick = () => {
        if (handleReuseDetails && upload) {
            handleReuseDetails(upload);
        } else if (uuid && username) {
            navigate(`/${username}/${uuid}`); // Adjust the path as necessary
        }
    };

    const [timeLeftForRelease, setTimeLeftForRelease] =
        useState<TimeLeftType>(''); // Store hours, minutes, seconds

    // Update the countdown every second if scheduledAt is available
    useEffect(() => {
        if (upload) {
            let scheduledTime: number | undefined = undefined;
            const latestVersion = upload.versions[upload.versions.length - 1];

            switch (getUploadStatus(upload)) {
                case UploadStatus.EARLYACCESS:
                    if (latestVersion.scheduledAtPublic) {
                        scheduledTime = Date.parse(
                            latestVersion.scheduledAtPublic
                        );
                    }
                    break;
                case UploadStatus.COMINGSOON_PUBLIC:
                    if (latestVersion.scheduledAtPublic) {
                        scheduledTime = Date.parse(
                            latestVersion.scheduledAtPublic
                        );
                    }
                    break;
                case UploadStatus.COMINGSOON_PRIVATE:
                    if (latestVersion.scheduledAtPrivate) {
                        scheduledTime = Date.parse(
                            latestVersion.scheduledAtPrivate
                        );
                    }
                    break;
                case UploadStatus.COMINGSOON_EARLYACCESS:
                    if (latestVersion.scheduledAtPrivate) {
                        scheduledTime = Date.parse(
                            latestVersion.scheduledAtPrivate
                        );
                    }
                    break;
            }

            if (scheduledTime) {
                const interval = setInterval(() => {
                    const remainingTime = calculateTimeLeft(scheduledTime);
                    setTimeLeftForRelease(remainingTime);

                    if (remainingTime === null) {
                        clearInterval(interval); // Stop the interval once the time is reached
                    }
                }, 1000); // Update every second

                return () => clearInterval(interval); // Cleanup on component unmount
            }
        }
    }, [upload]);

    return (
        <Card
            sx={{
                cursor: uuid ? 'pointer' : undefined,
                transition: 'transform 0.2s', // This will allow smooth transformation
                '&:hover': {
                    filter: 'brightness(1.1)', // Apply brightness on hover
                    transition: 'all 0.06s ease', // Apply transition for smooth effect
                },
            }}
            onClick={uuid ? handleClick : undefined}
        >
            {upload ? (
                getUploadStatus(upload) === UploadStatus.PENDING ? (
                    <PreviewCardStatusBanner
                        colorDark={theme.palette.warning.main}
                        colorMain={theme.palette.warning.main}
                        icon={<Pending />}
                        status={'Pending'}
                        information={'Under Review'}
                    />
                ) : getUploadStatus(upload) === UploadStatus.REJECTED ? (
                    <PreviewCardStatusBanner
                        colorDark={theme.palette.error.main}
                        colorMain={theme.palette.error.main}
                        icon={<Cancel />}
                        status={'Rejected'}
                        information={
                            upload.declined
                                ? removalDay(upload.declined.declinedAt)
                                : ''
                        }
                    />
                ) : hasOpenReports(upload, user) ? (
                    <PreviewCardStatusBanner
                        colorDark={theme.palette.error.main}
                        colorMain={theme.palette.error.main}
                        icon={<NewReleases />}
                        status={'Reported'}
                        information={upload.pendingReports + ' Reports'}
                    />
                ) : getUploadStatus(upload) ===
                  UploadStatus.COMINGSOON_PUBLIC ? (
                    <PreviewCardStatusBanner
                        colorDark={theme.palette.warning.main}
                        colorMain={theme.palette.warning.main}
                        icon={<HourglassTop />}
                        status={'Releasing'}
                        information={renderTimeLeft(timeLeftForRelease)}
                    />
                ) : getUploadStatus(upload) ===
                  UploadStatus.COMINGSOON_PRIVATE ? (
                    <PreviewCardStatusBanner
                        colorDark={OrangeColor}
                        colorMain={OrangeColor}
                        icon={<HourglassTop />}
                        status={'Releasing'}
                        information={renderTimeLeft(timeLeftForRelease)}
                    />
                ) : getUploadStatus(upload) === UploadStatus.EARLYACCESS ? (
                    <PreviewCardStatusBanner
                        colorDark={theme.palette.warning.main}
                        colorMain={theme.palette.warning.main}
                        icon={<HourglassTop />}
                        status={'Public Release'}
                        information={renderTimeLeft(timeLeftForRelease)}
                    />
                ) : getUploadStatus(upload) ===
                  UploadStatus.COMINGSOON_EARLYACCESS ? (
                    <PreviewCardStatusBanner
                        colorDark={OrangeColor}
                        colorMain={OrangeColor}
                        icon={<HourglassTop />}
                        status={'Early Access'}
                        information={renderTimeLeft(timeLeftForRelease)}
                    />
                ) : null
            ) : null}

            {upload && !isUploadPublic(upload) ? (
                <Box sx={{ position: 'relative' }}>
                    <Box
                        sx={{
                            position: 'absolute', // Ensures it's at the top-right
                            right: 0,
                            top: 0,
                            padding: '6px 6px',
                            borderRadius: '4px',
                            zIndex: 10,
                        }}
                    >
                        <PatreonIcon />
                    </Box>
                </Box>
            ) : null}

            {!imgError && image ? (
                <>
                    <CardMedia
                        sx={{
                            height: 210,
                            objectPosition:
                                type.name === 'arena' ? 'center' : 'top',
                        }}
                        {...(image && {
                            component: 'img',
                            alt: title,
                            height: '100%',
                            image: image,
                        })}
                        onError={() => setImgError(true)} // Handle broken images
                    />
                </>
            ) : (
                <CardMedia
                    sx={{
                        height: 210, // Set the height you want for the CardMedia
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Arrange items in a column
                            justifyContent: 'center', // Center vertically
                            alignItems: 'center', // Center horizontally
                            height: '100%', // Take full height of CardMedia
                        }}
                    >
                        <NoImage />
                    </Box>
                </CardMedia>
            )}

            <CardContent
                sx={{
                    position: 'relative',
                    overflow: 'hidden',
                    backgroundColor: theme.palette.secondary.main,
                    height: '105px',
                }}
            >
                {/* Background image with blur */}
                {!imgError && image && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: 'full',
                            overflow: 'hidden',
                            backgroundColor: theme.palette.secondary.light,
                        }}
                    >
                        <img
                            src={image} // Use the image prop dynamically
                            alt="blurred background"
                            style={{
                                /*
                                width: '538px',
                                height: '286px',
                                margin: '-45px 25px',
                                filter: 'blur(100px)',

                                 */
                                width: '538px',
                                height: '286px',
                                margin: '-45px 2px',
                                filter: 'blur(40px)',
                                opacity: 0.2,
                            }}
                        />
                    </Box>
                )}

                <div style={{ position: 'relative', zIndex: 2 }}>
                    <Typography
                        textAlign={'left'}
                        variant="caption"
                        sx={{
                            color: theme.palette.warning.main,
                            display: 'block', // Ensure it behaves as a block-level element
                            textAlign: 'left', // Explicitly set text alignment
                            marginBottom: 0, // Reset margin if necessary
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <>{games.length > 0 && games[0].name.toUpperCase()}</>
                    </Typography>
                    <Typography
                        textAlign={'left'}
                        variant="h5"
                        sx={{
                            marginBottom: 1,
                            marginTop: 1,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                        }}
                    >
                        {title.toUpperCase()}
                    </Typography>
                    <Typography
                        textAlign={'left'}
                        variant="body2"
                        fontSize={'14px'}
                        color={TextCardPreviewDescriptionColor}
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                        }}
                    >
                        {description}
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default PreviewCard;
